import { ResponseError, extend } from 'umi-request';
import {
  BackendUrls,
  LOCAL_STORAGE_BACKEND,
} from '../components/layout/BackendSwitcher';

/* 
 * Helper for requests
 * Adds the base url to all requests and handles errors
 * See https://github.com/umijs/umi-request
 */ 

const API_BASE_DEFAULT: string =
  process.env.REACT_APP_BACKEND || 'http://localhost:3001';
const API_BASE_SETTING: string = localStorage.getItem(LOCAL_STORAGE_BACKEND) || '';

// @ts-ignore
export let API_BASE_URL =
  API_BASE_SETTING && API_BASE_SETTING !== 'default'
    ? BackendUrls[API_BASE_SETTING]
    : API_BASE_DEFAULT;

export const setBaseUrl = (newBaseUrl: string | null) => {
  API_BASE_URL = newBaseUrl || API_BASE_DEFAULT;
};

const errorHandler = function (error: ResponseError<any>) {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.log(error.response.status);
    console.log(error.response.headers);
    console.log(error.data);
    console.log(error.request);
  } else {
    // The request was made but no response was received or error occurs when setting up the request.
    console.log(error.message);
  }
  throw error;
};

const request = extend({
  //prefix: "http://api/",
  timeout: 60000 * 5,
  errorHandler,
  redirect: 'follow',
});

request.use(
  async (ctx, next) => {
    ctx.req.url = API_BASE_URL + ctx.req.url;
    await next();
  },
  { core: true },
);

export default request;
