import { Player } from '@lottiefiles/react-lottie-player';
import { Space, Typography } from 'antd';
import animationData from './assets/loading_ommax.json';

const { Text } = Typography;

interface LottieLoadingProps {
  tip?: string | React.ReactNode;
  height?: number;
}

const LottieLoading: React.FC<LottieLoadingProps> = (props) => {
  const { tip, height = 200 } = props;

  return (
    <Space direction="vertical" style={{ backdropFilter: 'blur(10px)' }}>
      <Player autoplay loop style={{ height }} src={animationData} />
      {!!tip && (
        <Text strong style={{ position: 'relative', top: -(height / 4) }}>
          {tip}
        </Text>
      )}
    </Space>
  );
};

export default LottieLoading;
