import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Authentication {
  // Zitadel
  //access_token?: string;
  name?: string;
  email?: string;
  given_name?: string;
  family_name?: string;
  "urn:zitadel:iam:user:resourceowner:id"?: string;
}

// can be used to flag features as ommax internal only (check against resourceowner of auth)
export const OMMAX_ORG_ID = "247938156106683657"

export interface IAuthState {
  auth: Authentication | null;
  isAuthenticated: boolean | null;
  error?: any; // error from backend
}

const initialState: IAuthState = {
  auth: null,
  isAuthenticated: null,
  error: undefined
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    localLogin: (state, action: PayloadAction<Authentication>) => {
      state.isAuthenticated = true;
      state.auth = action.payload;
    },
    localLogout: (state, action: PayloadAction) => {
      state.isAuthenticated = false;
      state.auth = null;
      state.error = undefined;
    },
    setError: (state, action: PayloadAction) => {
      state.error = action.payload;
    },
  },
});

export const { localLogin, localLogout, setError } = slice.actions;

export default slice.reducer;
