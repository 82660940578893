import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Tooltip } from 'antd';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 16, offset: 8 },
  },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const ToggableTags = (props: any) => {
  const {
    label = 'Crawled pages for secondary keywords',
    nestedName = undefined,
    verifyUrl = true,
    maxCount = 10,
    entity = 'URL',
    ...otherProps
  } = props;
  return (
    <Form.List {...otherProps} initialValue={['']}>
      {(fields, { add, remove }, { errors }) => (
        <>
          {fields.map((field, index) => (
            <Form.Item
              {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
              label={index === 0 ? label : ''}
              required={false}
              key={field.key}
            >
              <Form.Item
                {...field}
                name={nestedName ? [field.name, nestedName] : field.name}
                validateTrigger={['onChange', 'onBlur']}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: `Please input ${entity} or delete this field`,
                  },
                  // @ts-ignore
                  ...(verifyUrl
                    ? [
                        {
                          type: 'url',
                          message: 'This field must be a valid URL.',
                        },
                      ]
                    : []),
                ]}
                noStyle
              >
                <Input placeholder={label} style={{ width: '93%' }} />
              </Form.Item>

              {
                /*the value here was one, but it created issues during generation - like having no faqs*/ fields.length >
                0 ? (
                  <Tooltip title={`Delete ${entity}`}><Button type="text" className="dynamic-delete-button"
                  onClick={() => remove(field.name)}
                  icon={<DeleteOutlined />}
                  style={{ marginLeft: 10 }}></Button></Tooltip>
                ) : null
              }
            </Form.Item>
          ))}
          <Form.Item {...tailLayout}>
            <Tooltip
              title={
                fields.length >= maxCount &&
                'Not more than ' + maxCount + ' items allowed'
              }
            >
              <Button
                type="dashed"
                onClick={() => add()}
                icon={<PlusOutlined />}
                disabled={fields.length >= maxCount}
              >
                {`Add ${entity}`}
              </Button>
            </Tooltip>
            <Form.ErrorList errors={errors} />
          </Form.Item>
        </>
      )}
    </Form.List>
  );
};

export default ToggableTags;
