import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { InputField } from "../../data/brands";
import { RootState } from "../../redux";
import DynamicInputBrandList from "./DynamicInputBrandList";
import DynamicInputDivider from "./DynamicInputDivider";
import DynamicInputText from "./DynamicInputText";

interface DynamicInputProps {
    page?: string;
}

export interface DynamicInputField {
    field: InputField & { type: string };
}


export const DynamicInput: React.FC<DynamicInputProps> = (props) => {
    const {page} = props;
    const settings = useSelector((state: RootState) => state.branding.settings);
    const fields = useMemo(() => settings?.brand_specific_input_fields?.filter(i => i.page === page), [page, settings])

    const getComponent = (type: string) => {
        if (type === "text" || type === "textarea") return DynamicInputText;
        if (type === "divider") return DynamicInputDivider;
        if (type === "brandList") return DynamicInputBrandList;
        return null;
    }

    return <>
        {fields?.map(input => {
            const Component = getComponent(input.type);
            return Component ? <Component key={input.name} field={input} /> : null;
        })}
    </>
}

export default DynamicInput;