import { DeleteOutlined, PlusOutlined, RedoOutlined } from '@ant-design/icons';
import type { InputRef } from 'antd';
import { Badge, Button, Input, Tag, Tooltip, message, theme } from 'antd';
import CheckableTag from 'antd/es/tag/CheckableTag';
import React, { useEffect, useRef, useState } from 'react';
import { IKeyword, KeywordCategory } from '../../data/scrapeResult';

/*
 * KeywordInput
 * Input component for antd's form component to handle keyword inputs
 * Each input can have three states (used, removed, stored) and new keywords up to specified count can be added
 */

interface KeywordInputProps {
  // onChange and value are passed from ant form
  onChange?: (data: IKeyword[] | undefined) => void;
  value?: IKeyword[];
  // maximum number of active keywords
  maximumActiveKeywords?: number;
  // keywords not to show (most likely primary keyword)
  hiddenKeywords?: string[]
}

const KeywordInput = (props: KeywordInputProps) => {
  const { onChange, value, maximumActiveKeywords = 5, hiddenKeywords = [] } = props;
  const { token } = theme.useToken();
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef<InputRef>(null);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  // returns true if new word can be set to active
  // returns false and shows error message otherwise
  const canAddActiveKeyword = () => {
    const keywordCount = value
      ?.map((i) => i.category)
      .filter((i) => i === KeywordCategory.USED).length;
    if (keywordCount && keywordCount >= maximumActiveKeywords) {
      messageApi.error(
        'Maximum amount of active keywords (' +
          maximumActiveKeywords +
          ') is reached',
      );

      return false;
    }
    return true;
  };

  // changes to state of defined keyword to given state
  const changeKeywordState = (
    keyword: string,
    newState = KeywordCategory.USED,
  ) => {
    const newTags = value?.map((tag) => ({
      ...tag,
      category: tag.label === keyword ? newState : tag.category,
    }));
    onChange?.(newTags);
  };

  // handles the enter of adding a new keyword
  const handleInputConfirm = () => {
    // keyword is new -> add and set as active
    if (
      inputValue &&
      value &&
      !value?.find((tag) => tag.label === inputValue)
    ) {
      onChange?.([
        {
          label: inputValue,
          category: canAddActiveKeyword()
            ? KeywordCategory.USED
            : KeywordCategory.STORED,
        },
        ...value!,
      ]);
    }
    // keyword is already in list -> set as active
    if (inputValue && value?.find((tag) => tag.label === inputValue)) {
      changeKeywordState(
        inputValue,
        canAddActiveKeyword() ? KeywordCategory.USED : KeywordCategory.STORED,
      );
    }
    // keyword is first keyword added (list is empty) -> add and set as active
    if (inputValue && !value) {
      onChange?.([
        {
          label: inputValue,
          category: canAddActiveKeyword()
            ? KeywordCategory.USED
            : KeywordCategory.STORED,
        },
      ]);
    }
    setInputVisible(false);
    setInputValue('');
  };

  // renders a single keyword, with functions for deleting and marking as active
  const forMap = (tag: IKeyword) => {
    if (hiddenKeywords.includes(tag.label)) {
      return null;
    }
    const tagElem = (
      <Badge
        count={
          <Button
            type="text"
            size="small"
            onClick={() =>
              changeKeywordState(
                tag.label,
                tag.category === KeywordCategory.REMOVED
                  ? KeywordCategory.STORED
                  : KeywordCategory.REMOVED,
              )
            }
          >
            {tag.category === KeywordCategory.REMOVED ? (
              <Tooltip title="Undo exclude keyword">
                <RedoOutlined />
              </Tooltip>
            ) : (
              <Tooltip title="Exclude keyword from result">
                <DeleteOutlined />
              </Tooltip>
            )}
          </Button>
        }
      >
        <CheckableTag
          key={tag.label}
          checked={tag.category === KeywordCategory.USED}
          onChange={(checked) =>
            changeKeywordState(
              tag.label,
              !checked
                ? KeywordCategory.STORED
                : canAddActiveKeyword()
                  ? KeywordCategory.USED
                  : KeywordCategory.STORED,
            )
          }
          style={{
            marginBottom: 10,
            textDecoration:
              tag.category === KeywordCategory.REMOVED
                ? 'line-through'
                : undefined,
          }}
        >
          {tag.label}
        </CheckableTag>
      </Badge>
    );
    return (
      <span
        key={tag.label}
        style={{ display: 'inline-block', marginRight: 15 }}
      >
        {tagElem}
      </span>
    );
  };

  // style for plus icon
  const tagPlusStyle: React.CSSProperties = {
    background: token.colorBgContainer,
    padding: 8,
    fontSize: 14,
    borderStyle: 'dashed',
    cursor: "pointer"
  };

  return (
    <>
      {contextHolder}
      <div style={{ marginTop: 16 }}>{value?.map(forMap)}</div>
      {inputVisible ? (
        <Input
          ref={inputRef}
          type="text"
          size="small"
          style={{ width: 250, marginBottom: 14 }}
          value={inputValue}
          placeholder='Press enter to create keyword'
          onChange={handleInputChange}
          onBlur={handleInputConfirm}
          onPressEnter={handleInputConfirm}
        /> 
      ) : (
        <Tag onClick={showInput} style={tagPlusStyle}>
          <PlusOutlined /> Add keyword
        </Tag>
      )}
    </>
  );
};
export default KeywordInput;
