import { Alert, Button, Card, Flex, Skeleton, Space, message } from 'antd';
import { MessageType } from 'antd/es/message/interface';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux';

const error = '';

interface LoginBoxProps {
  handleLogin: () => void;
  userDataStage: UserDataState | undefined; 
}

export enum UserDataState {
  BRANDS,
  WAITING,
  USER_INFORMATION,
}
const UserDataStateMessage = {
  [UserDataState.BRANDS]: "Retrieving your organization's information",
  [UserDataState.WAITING]: "We're currently undergoing a brief maintenance, which may last up to 15 minutes. This page will refresh automatically .",
  [UserDataState.USER_INFORMATION]: "Retrieving your user information",
}


const LoginBox: React.FC<LoginBoxProps> = (props) => {
  const { handleLogin, userDataStage } = props;
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);
  const [messageApi, contextHolder] = message.useMessage();
  const messageRef = useRef<MessageType | undefined>(undefined);  
  const timerIdRef = useRef<any | null>(null);

  const activateTimer = () => {
    timerIdRef.current = setTimeout(() => {
      showMessage(UserDataState.WAITING)
    }, 10000); 
  };

  const showMessage = (stage: UserDataState) => {
    // close currently open message
    messageRef.current?.();

    messageRef.current = messageApi.open({
      type: 'loading',
      content: UserDataStateMessage[stage],
      duration: 0,
    });
  }

  useEffect(() => {
    // close currently open message
    messageRef.current?.();

    if (userDataStage !== undefined)  {
      showMessage(userDataStage)
      activateTimer();
    }

    return () => {
      if (timerIdRef.current) {
        clearTimeout(timerIdRef.current);
      }
    };
  }, [userDataStage])

  const LoginButton = () => (
    <Button
      type="primary"
      onClick={() =>
        handleLogin()
      }
    >
      Sign in
    </Button>
  );

  const LoginSkeleton = () => <Skeleton.Button active style={{ width: 120 }} />;

  return (
    <Flex style={boxStyle} justify="center" align="center">
      <Space direction="vertical">
        {!!error && (
          <Alert
            message={'Authentication failed: ' + error}
            type="error"
            showIcon
          />
        )}
        <Card
          title="OMMAX SEO content generator"
          bordered={false}
          style={{ width: 300 }}
          actions={[
            (isAuthenticated === null || userDataStage !== undefined) ? <LoginSkeleton /> : <LoginButton />,
          ]}
        >
          Welcome to OMMAX SEO content generator! <br />
          <br />
          Please sign in with your organization account
        </Card>
      </Space>
      {contextHolder}
    </Flex>
  );
};

const boxStyle: React.CSSProperties = {
  width: '100%',
  height: '80vh',
};

export default LoginBox;
