import { syllable } from 'syllable';
import wordsCount from 'words-count';


/*
 * Helper to deal with texts
 * Used for quality check
 */ 

export function countSentences(text: string): number {
  return text.split(/[.!?](?:\s|$)/).filter(Boolean).length;
}
export function countWords(text: string): number {
  return wordsCount(text);
  //return text.trim().split(/\s+/).length;
}
export function countSyllables(word: string): number {
  return syllable(word);
}
export function countTotalSyllables(text: string): number {
  // Split text into words
  const words: string[] = text.split(/\s+/).filter(Boolean);
  // Count syllables in each word and sum them up
  return words.reduce(
    (count: number, word: string) => count + countSyllables(word),
    0,
  );
}
