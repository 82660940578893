/*
 * When concatenating outputs from the execution plan, there might be one output ending with a non closed html tag as it is streamed lively 
 * Example: Test <h Test
 * This function removes all non closed html tags to improve preview 
 */

export function removeNonClosedTags(html: string) {
  const tagRegex = /<\/?([a-zA-Z][^>\s]*)[^>]*>/g;
  const stack = [];
  const removalIndexes = [];

  let match;
  while ((match = tagRegex.exec(html)) !== null) {
    const fullTag = match[0];
    const tagName = match[1];
    const isOpeningTag = !fullTag.startsWith('</');
    const tagPosition = match.index;

    if (isOpeningTag) {
      stack.push({
        tagName,
        position: tagPosition,
        fullTagLength: fullTag.length,
      });
    } else {
      if (stack.length > 0 && stack[stack.length - 1].tagName === tagName) {
        stack.pop();
      } else {
        removalIndexes.push({ start: tagPosition, length: fullTag.length });
      }
    }
  }

  // Any unmatched opening tags left in the stack should be removed.
  stack.forEach((tag) =>
    removalIndexes.push({ start: tag.position, length: tag.fullTagLength }),
  );

  // Removing tags from the end so that indexes don't get disrupted.
  removalIndexes
    .sort((a, b) => b.start - a.start)
    .forEach((removal) => {
      html =
        html.slice(0, removal.start) +
        html.slice(removal.start + removal.length);
    });

  return html;
}

/*
 * Removes ALL html
 */
export function stripHtml(html: string | undefined) {
  return html?.replace(/<[^>]*>/g, '');
}

export function replaceGermanUmlauts(str: string | undefined) {
  return str?.replace(/&([auoAUO])uml;/g, (match, p1) => {
      switch (p1) {
          case 'a': return 'ä';
          case 'u': return 'ü';
          case 'o': return 'ö';
          case 'A': return 'Ä';
          case 'U': return 'Ü';
          case 'O': return 'Ö';
          default: return match; // No replacement if no match is found (shouldn't happen in this case)
      }
  });
}