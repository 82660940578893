import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../redux";
import { OMMAX_ORG_ID } from "../redux/auth";

const usePermissions = () => {
    const auth = useSelector((state: RootState) => state.auth);
    const isAdmin = useMemo(() => auth.auth?.['urn:zitadel:iam:user:resourceowner:id'] === OMMAX_ORG_ID, [auth])
    return {isAdmin};
}

export default usePermissions;