import {
  AiInterfaceCategoryForm,
  AiInterfaceLinkForm,
} from '../pages/components/AiInterfaceCategory';
import request from '../utils/request';

export interface IScrapeRequestResult {
  scrapeUrls?: string[];
  faqItems?: string[];
  scrapeUrlDisabled?: boolean;
}

export const fetchUrlsFromCategory = async (data: AiInterfaceCategoryForm) => {
  const res = await request<IScrapeRequestResult>('/scrapeUrlsFromCategory', {
    method: 'post',
    data,
  });
  return res;
};

export const fetchUrlsFromLink = async (data: AiInterfaceLinkForm) => {
  const res = await request<IScrapeRequestResult>('/scrapeUrlsFromLink', {
    method: 'post',
    data,
  });
  return res;
};
