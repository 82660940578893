import {
  CheckOutlined,
  DeleteOutlined,
  SaveOutlined,
  ShareAltOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { Button, Card, Modal, Space, Tooltip, Typography, message } from 'antd';
import Meta from 'antd/es/card/Meta';
import { DateTime } from 'luxon';
import React, { MutableRefObject, useEffect, useState } from 'react';
import {
  ResultStorageData,
  resultStorageCreate,
  resultStorageSave,
} from '../../../data/result';
import useSavedItem from '../../../hooks/useSavedItem';
import { AiInterfaceCategoryForm } from '../../components/AiInterfaceCategory';
import { AiInterfaceDetailsForm } from '../../components/AiInterfaceDetails';
import { AiInterfaceScrapeUrlForm } from '../../components/AiInterfaceKeyword';

interface AiInterfaceResultSaveProps {
  editorRef: MutableRefObject<any>;
  uuidRef: MutableRefObject<string | null>;
  initTextRef: MutableRefObject<string | null>;
  params: Partial<
    AiInterfaceCategoryForm & AiInterfaceDetailsForm & AiInterfaceScrapeUrlForm
  >;
  savedResult: ResultStorageData | undefined;
  editDuration: number;
  loading: boolean;
  onSave?: (date: DateTime | undefined) => void;
}

const AiInterfaceResultSave: React.FC<AiInterfaceResultSaveProps> = (props) => {
  const {
    editorRef,
    initTextRef,
    uuidRef,
    params,
    savedResult,
    editDuration,
    loading,
    onSave,
  } = props;
  const [name, setName] = useState<string>();
  const [id, setId] = useState<string>();
  const [saving, setSaving] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [lastSavedDate, setLastSavedDate] = useState<DateTime>();
  const [modal, contextHolder] = Modal.useModal();
  const [messageApi, contextHolderMessage] = message.useMessage();
  const { deleteSavedItem, shareSavedItem } = useSavedItem();

  useEffect(() => {
    onSave?.(lastSavedDate);
  }, [lastSavedDate]);

  const shareItem = async () => {
    shareSavedItem({ id, name }, messageApi);
  };

  const deleteItem = async () => {
    if (!id) return;
    setDeleting(true);
    const ok = await deleteSavedItem({ id, name }, modal, messageApi);
    if (ok) {
      setId(undefined);
      setLastSavedDate(undefined);
    }
    setDeleting(false);
  };

  const initialSaveItem = async () => {
    setSaving(true);
    const date = DateTime.now().toLocaleString(DateTime.DATETIME_SHORT);
    const defaultName = params.primaryKeyword + ' - ' + date;
    const saveData = {
      name: defaultName,
      text: editorRef.current?.getContent(),
      editDuration,
      initText: initTextRef.current || undefined,
      generationUuid: uuidRef.current || undefined,
      params,
    };

    try {
      const save = await resultStorageCreate(saveData);
      messageApi.success(defaultName + ' was saved successfully');
      setName(defaultName);
      setId(save.id);
      setLastSavedDate(DateTime.now());
    } catch (e) {
      messageApi.error(
        'Could not save result. Please try again or contact the support.',
      );
    }

    setSaving(false);
  };

  const saveItem = async (override: Partial<ResultStorageData> = {}) => {
    setSaving(true);

    try {
      const saveData = {
        name: name!,
        text: editorRef.current?.getContent(),
        editDuration,
        params,
        ...override,
      };
      await resultStorageSave(id!, saveData);
      messageApi.success(saveData.name + ' was saved successfully');
      setLastSavedDate(DateTime.now());
      setName(saveData.name);
    } catch (e) {
      messageApi.error(
        'Could not save result. Please try again or contact the support.',
      );
    }
    setSaving(false);

    /*const ok = await modal.confirm({
      title: 'Override changes',
      content: "xx was modified on the server at xxx. Are you sure you want to save your changes?",
    });
    if (ok) {
        setSaving(false)
    }*/
  };

  // loading indicates a new generation run, so clean id
  useEffect(() => {
    if (loading) {
      setId(undefined);
      setName(undefined);
    }
  }, [loading]);

  // access from a already stored item
  useEffect(() => {
    if (savedResult) {
      setId(savedResult.id);
      setName(savedResult.name);
      if (savedResult.timestamp)
        setLastSavedDate(DateTime.fromISO(savedResult.timestamp));
    }
  }, [savedResult]);

  const SaveButton = () => {
    /*if (id) {
      const items: MenuProps['items'] = [
        {
          label: 'Save',
          key: '1'
        },
        {
          label: 'Save as',
          key: '2'
        }
      ];
      const onClickSave = (e: any) => {
        console.log(e)
      }
      return <Dropdown.Button 
      menu={{  
        items,
        onClick: onClickSave
      }} 
      loading={saving}
      type="text"
      onClick={() => (id ? saveItem() : initialSaveItem())}
      >
       <SaveOutlined /> Save
      </Dropdown.Button>;
    }*/
    return (
      <Button
        type="text"
        icon={<SaveOutlined />}
        loading={saving}
        onClick={() => (id ? saveItem() : initialSaveItem())}
      >
        Save
      </Button>
    );
  };

  const DeleteButton = () => {
    return (
      <Button
        type="text"
        icon={<DeleteOutlined />}
        loading={deleting}
        onClick={() => deleteItem()}
        danger
      >
        Delete
      </Button>
    );
  };

  const SuccessButton = () => {
    return (
      <Button
        type="text"
        icon={<CheckOutlined style={{ color: 'green' }} />}
        disabled
        onClick={() => null}
      >
        Already saved
      </Button>
    );
  };

  const ShareButton = () => {
    return (
      <Tooltip title="Share link to result">
        <Button
          type="text"
          icon={<ShareAltOutlined />}
          onClick={() => shareItem()}
        >
          Share
        </Button>
      </Tooltip>
    );
  };

  // not saved yet
  return (
    <>
      {!id ? (
        <Card actions={[<SaveButton key="save" />]}>
          <Meta
            title={
              <>
                <WarningOutlined style={{ color: 'red' }} /> Content-piece not saved yet
              </>
            }
            description="Click the save button below to retain content-piece"
          />
        </Card>
      ) : (
        <Card
          actions={[
            <ShareButton key="share" />,
            <DeleteButton key="delete" />,
            <SaveButton key="save" />,
          ]}
        >
          <Meta
            description={
              <Space direction="vertical" size="small">
                <Typography.Text
                  editable={{
                    onChange: (val) => {
                      if (val !== name) saveItem({ name: val });
                    },
                    maxLength: 150,
                    triggerType: ['icon', 'text'],
                  }}
                  style={{ marginTop: 5, marginLeft: 15, fontSize: 18 }}
                >
                  {name}
                </Typography.Text>
                <span style={{ marginLeft: 15 }}>
                  Last saved:{' '}
                  {lastSavedDate?.toLocaleString(DateTime.DATETIME_FULL)}
                </span>
              </Space>
            }
          />
        </Card>
      )}
      {contextHolder}
      {contextHolderMessage}
    </>
  );
};
export default AiInterfaceResultSave;
