import request from '../utils/request';
import { IBrandRecord } from './brands';

export const brandAdminList = async (promote_candidates: boolean = false) => {
  const res = await request<Partial<IBrandRecord[]>>('/brand_admin/list?promote_candidates='+promote_candidates, {
    method: 'get',
  });
  return res;
};

export const brandAdminCreate = async (data: IBrandRecord) => {
  const res = await request<Partial<IBrandRecord>>('/brand_admin/create', {
    method: 'put',
    data,
  });
  return res;
};

export const brandAdminSave = async (
  id: string,
  data: Partial<IBrandRecord>,
) => {
  const res = await request<Partial<IBrandRecord>>('/brand_admin/' + id, {
    method: 'patch',
    data,
  });
  return res;
};

export const brandAdminDelete = async (id: string) => {
  const res = await request<Partial<IBrandRecord>>('/brand_admin/' + id, {
    method: 'delete',
  });
  return res;
};


export const brandAdminPromote = async (id: string) => {
  const res = await request<Partial<IBrandRecord>>('/brand_admin/promote/' + id, {
    method: 'post',
  });
  return res;
};


export const brandAdminGet = async (id: string) => {
  const res = await request<IBrandRecord>('/brand_admin/' + id, {
    method: 'get',
  });
  return res;
};


export const brandAdminGetHistory = async (id: string) => {
  const res = await request<{list: IBrandRecord[]}>('/brand_admin/history/' + id, {
    method: 'get',
  });
  return res;
};
