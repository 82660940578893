import { MessageInstance } from "antd/es/message/interface";
import { HookAPI } from "antd/es/modal/useModal";
import { ResultStorageData, resultStorageDelete } from "../data/result";

const useSavedItem = () => {

    const savedItemLink = (id: string | undefined) => {
        return  window.location.protocol +
        '//' +
        window.location.hostname +
        '/saved/' +
        id;
    }

    const deleteSavedItem = async (item: Partial<ResultStorageData>, modal: HookAPI, message: MessageInstance) => {
        if (!item.id) return;
        const ok = await modal.confirm({
          title: 'Are you sure?',
          content: "Are you sure you want to delete '" + item.name + "'?",
        });
        if (ok) {
          try {
            await resultStorageDelete(item.id);
            message.success('Result was deleted successfully');
            return true;
          } catch (e) {
            message.error(
              'Could not delete result. Please try again or contact the support.',
            );
            return false;
          }
        }
      }; 

      const shareSavedItem = async (item: Partial<ResultStorageData>, message: MessageInstance) => {
        const {id, name} = item;
        const content = savedItemLink(id);
    
        const blobPlain = new Blob([content!], {
          type: 'text/plain',
        });
        const data = [
          new ClipboardItem({
            'text/plain': blobPlain,
          }),
        ];
    
        // write to clipboard
        navigator.clipboard.write(data).then(
          () => {
            message.success(
              'Link to '+name+' copied to clipboard. Access restricted to authenticated organization members only.',
            );
          },
          () => {
            message.open({
              type: 'error',
              content:
                'Could not copy to clipboard - please check your browser settings',
            });
          },
        );
      };
      return {deleteSavedItem, shareSavedItem, savedItemLink}
}

export default useSavedItem;