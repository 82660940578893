import {
  CopyOutlined,
  LinkOutlined,
  LoadingOutlined,
  SlidersOutlined,
  TagsOutlined
} from '@ant-design/icons';
import { Alert, Spin, StepProps, Steps, message } from 'antd';
import React, { ReactNode, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Feedback from '../components/layout/Feedback';
import { ICategoryList, fetchCategoryList } from '../data/category';
import LottieLoading from '../lottie/LottieLoading';
import { AppDispatch, RootState } from '../redux';
import { setParams } from '../redux/generationProcess';
import AiInterfaceCategory from './components/AiInterfaceCategory';
import AiInterfaceDetails from './components/AiInterfaceDetails';
import AiInterfaceKeyword from './components/AiInterfaceKeyword';
import AiInterfaceResult from './result';
import './styles.css';

/*
 * AiInterface
 * Contains the different steps, navigation logic and initial category fetch logic
 */

// steps available on the page
export enum AiStep {
  CATEGORY,
  KEYWORDS,
  DETAILS,
  RESULT,
}

const AiInterface: React.FC = () => {
  const [step, setStep] = useState<AiStep>(AiStep.CATEGORY);
  const [loading, setLoading] = useState<boolean>(false);
  const [categoryList, setCategoryList] = useState<ICategoryList>();
  const [categoryLoading, setCategoryLoading] = useState<boolean>(false);
  const [clearData, setClearData] = useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();
  const branding = useSelector((state: RootState) => state.branding.data);
  const dispatch = useDispatch<AppDispatch>();
  const settings = useSelector((state: RootState) => state.branding.settings);
  const { urlStep } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
      const parsedUrlStep = parseInt(urlStep || "0", 10);
      if (parsedUrlStep === step) return;

      // navigation backwards is always allowed
      if (parsedUrlStep < step) {
        navigateToStepInternal(parsedUrlStep);
      }

      // navigation forwards is not allowed by url
      if (parsedUrlStep > step) {
        messageApi.error("Navigation forward is only possible with the submit button")
      }
  }, [urlStep]);

  const navigateToStepInternal  = (nextStep: AiStep, currentStep = step) => {
    setStep(nextStep)
  }
  const navigateToStep  = (nextStep: AiStep, currentStep = step) => {
    let navigateTo = nextStep;

    if (nextStep === AiStep.KEYWORDS && currentStep === AiStep.CATEGORY && settings?.disableLinkCrawler) navigateTo = AiStep.DETAILS
    else if (nextStep === AiStep.KEYWORDS && currentStep === AiStep.DETAILS && settings?.disableLinkCrawler) navigateTo = AiStep.CATEGORY

    navigate(`/generate/${navigateTo}`);
    setStep(navigateTo)
  }

  // reset data
  const cleanStart = () => {
    setClearData(true);
    dispatch(setParams({}))
    
    // we don't have a proper reset functionality for the states of forms
    // we would need to move everyting to redux, so let's hide everything for a few ms which clears the states
    setStep(0);
    window.setTimeout(() => setClearData(false), 2000);
  };

  // navigation properties shared for all pages
  const navigationProps = {
    back: () => {
      navigateToStep(step - 1);
    },
    next: () => {
      navigateToStep(step + 1);
    },  
    cleanStart,
    setStepsLoading: setLoading,
    messageApi,
  };

  // wraps the current active icon with a spinner if loading is true
  const wrapIconWithLoading = (
    icon: ReactNode,
    currStep: AiStep,
  ): ReactNode => {
    if (!loading || currStep !== step) return icon;
    return <LoadingOutlined />;
  };

  // list of all pages
  const AiStepData: StepProps[] = [
    {
      title: 'Parameters and settings',
      icon: wrapIconWithLoading(<SlidersOutlined />, AiStep.CATEGORY),
    },
    ...(!settings?.disableLinkCrawler ? [ {
      title: 'Links',
      icon: wrapIconWithLoading(<LinkOutlined />, AiStep.KEYWORDS),
    }]:[]),
    {
      title: 'Keywords and FAQ',
      icon: wrapIconWithLoading(<TagsOutlined />, AiStep.DETAILS),
    },
    {
      title: 'SEO-optimized text',
      icon: wrapIconWithLoading(<CopyOutlined />, AiStep.RESULT),
    },
  ];

  // fetches the categories on initial page opening
  const init = async () => {
    setCategoryLoading(true);

    try {
      const reqCategoryList = await fetchCategoryList();
      if (!reqCategoryList) throw new Error('Empty response');
      setCategoryList(reqCategoryList);
    } catch (error: any) {
      messageApi.open({
        type: 'error',
        content:
          'Fetching categories failed with the following message: ' +
          (error?.response?.status || error),
      });
    }

    setCategoryLoading(false);
  };

  // call init function once
  useEffect(() => {
    if (settings?.showCategoryList) init();
  }, [branding?.name]);
  
  return (
    <>
      {contextHolder}
      <Steps
        onChange={(s) => {
          if (process.env.REACT_APP_ENV === "local" || s < step) navigateToStep(s);
          else
            message.info(
              'To continue to the next step, use the submit button below the form',
            );
        }}
        items={AiStepData}
        current={step}
        style={{ marginBottom: 25 }}
      />

      {settings?.alert && <Alert {...settings.alert} />}

      {!clearData ? (
        <>
          <div
            style={{ display: step !== AiStep.CATEGORY ? 'none' : undefined }}
          >
            {categoryLoading ? (
              <Spin
                indicator={
                  <LottieLoading tip="Fetching cosmic wisdom from the intergalactic database... Please hold onto your space helmets!" />
                }
                style={{ height: 300, width: '100%' }}
              />
            ) : (
              <AiInterfaceCategory
                {...navigationProps}
                categoryList={categoryList || {categories: []}}
              />
            )}
          </div>
          <div
            style={{ display: step !== AiStep.KEYWORDS ? 'none' : undefined }}
          >
            <AiInterfaceKeyword {...navigationProps} />
          </div>
          <div
            style={{ display: step !== AiStep.DETAILS ? 'none' : undefined }}
          >
            <AiInterfaceDetails {...navigationProps} />
          </div>
          <div style={{ display: step !== AiStep.RESULT ? 'none' : undefined }}>
            <AiInterfaceResult {...navigationProps} step={step} />
          </div>
        </>
      ) : (
        <>
          <Spin
            indicator={
              <LottieLoading tip="I guess even digital amnesia is real – form's memory just got wiped!" />
            }
            style={{ height: 300, width: '100%' }}
          />
        </>
      )}
      <Feedback step={step} />
    </>
  );
};
export default AiInterface;
