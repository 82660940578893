import { Breadcrumb, Modal, Spin, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Feedback from '../../components/layout/Feedback';
import { ResultStorageData, resultStorageGet } from '../../data/result';
import LottieLoading from '../../lottie/LottieLoading';
import { AppDispatch, RootState } from '../../redux';
import { setBrand } from '../../redux/branding';
import { setParams } from '../../redux/generationProcess';
import NotFoundItem from '../NotFoundItem';
import AiInterfaceResult from '../result';
import '../styles.css';

const SavedItem: React.FC = () => {
  const { id } = useParams();
  const [messageApi, contextHolder] = message.useMessage();
  const [modal, modalContextHolder] = Modal.useModal();
  const [loading, setLoading] = useState<boolean>(false);
  const [showNotFound, setShowNotFound] = useState<boolean>(false);
  const [item, setItem] = useState<ResultStorageData>();
  const branding = useSelector((state: RootState) => state.branding.brand);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  
  const finallyLoadItem = async (res: ResultStorageData) => {
    setShowNotFound(false)
    setLoading(true)
    setItem(res)
    dispatch(setParams(res.params))
    setLoading(false)
  }

  const loadItem = async () => {
    if (!id) return;

    setLoading(true)
    try {
      const res: any = await resultStorageGet(id);

      // check if correct brand is active 
      if (res.organization !== branding?.id) {
        // needs confirmation in future, currently change automatic
        /* const ok = await modal.confirm({
          title: "Change organization",
          content: "The result you requested is associated with the organization '"+res.organization+"'. To access the result, you have to switch to this organization."
        }); */
        const ok = true;
        if (ok) {
          dispatch(setBrand(res.organization));
        } else {
          setShowNotFound(true)
        }
      } else {
        finallyLoadItem(res)
      }
    }
    catch (e) {
      setShowNotFound(true)
    }
  }

  useEffect(() => {
    if (!id) return;

    setShowNotFound(false)
    loadItem();
  }, [id, branding])
  
  if (showNotFound) return <NotFoundItem />

  return <>
    {contextHolder}
    <Breadcrumb
    items={[
      {
        title: 'Home',
        onClick: () => navigate("/")
      },
      {
        title: "My organization's results",
        onClick: () => navigate("/saved")
      },
      {
        title: item?.name || "Saved item",
      }
    ]}
  />
    <Spin indicator={<LottieLoading tip="Retrieving your saved result from the universe" />} spinning={loading}>
      <AiInterfaceResult messageApi={messageApi} savedResult={item} />
    </Spin>
    <Feedback step="saved_result" additionalInput={{item: id}} />
    {modalContextHolder}
  </>
};
export default SavedItem;
