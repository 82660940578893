import Ajv from "ajv";
import { Breadcrumb, Button, Form, Input, Modal, Space, Spin, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import JsonEditorInput from '../../components/JsonEditorInput';
import { brandAdminCreate, brandAdminGet, brandAdminSave } from '../../data/brandAdmin';
import { IBrandRecord } from '../../data/brands';
import LottieLoading from '../../lottie/LottieLoading';
import { AppDispatch, RootState } from '../../redux';
import NotFoundItem from '../NotFoundItem';
import SettingsSchema from "./SettingsSchema.json";

import '../styles.css';

const ajv = new Ajv({allErrors: true});

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 29 },
};

const tailLayout = {
  wrapperCol: { offset: 4, span: 20 },
};

const removeSystemAttributes = (originalObject: any) => Object.fromEntries(
  Object.entries(originalObject).filter(([key]) => !key.startsWith('_') && !["id", "label", "organization"].includes(key))
);

const BrandAdminForm: React.FC = () => {
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [messageApi, contextHolder] = message.useMessage();
  const [modal, modalContextHolder] = Modal.useModal();
  const [loading, setLoading] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [showNotFound, setShowNotFound] = useState<boolean>(false);
  const [item, setItem] = useState<IBrandRecord>();
  const branding = useSelector((state: RootState) => state.branding.brand);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  
  
  const validateOnFinish = async (values: any) => {
    const validate = ajv.compile(SettingsSchema)
    const valid = validate(values.config)

    if (!valid) {
      modal.confirm({
        title: 'Validation errors',
        content: "Validation of json schema failed with following error(s). You can fix them or continue anyway. "+validate.errors?.map((i, idx) => (idx+1)+") "+i.instancePath+": "+i.message).join("\n, "),
        onOk: async () => {
          onFinish(values)
        }
      });
    }
    else {
      onFinish(values)
    }
    console.log(validate.errors)
  }

  const onFinish = async (values: any) => {
    setSubmitting(true)
    const data = {
      ...values.config,
      label: values.label,
    }

    if (!id || id === "create") {
      await brandAdminCreate(data);
    }
    else {
      await brandAdminSave(id, data);
    }
    setSubmitting(false)
    
    if (id === "create") {
      navigate("/brand-admin")
    } else {
      loadItem();
      window.scrollTo({top: 0, behavior: 'smooth'});
      messageApi.success("Brand saved successfully")
    }
  };

  const loadItem = async () => {
    if (!id || id === "create") {
      const duplicate = searchParams.get("baseBrand");
      if (duplicate) {
        const res = await brandAdminGet(duplicate);
        form.setFieldsValue({
          label: "Copy of "+res.label,
          config: removeSystemAttributes(res)
        })
      }
    } else {
      setLoading(true)
      try {
        const res = await brandAdminGet(id);
        setItem(res)
        form.setFieldsValue({
          label: res.label,
          config: removeSystemAttributes(res)
        })
      }
      catch (e) {
        setShowNotFound(true)
      }
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!id) return;

    setShowNotFound(false)
    loadItem();
  }, [id, branding])
  
  if (showNotFound) return <NotFoundItem />

  return <>
    {contextHolder}
    <Breadcrumb
    items={[
      {
        title: 'Home',
        onClick: () => navigate("/")
      },
      {
        title: "Brand administration",
        onClick: () => navigate("/brand-admin")
      },
      {
        title: item?.label || "Create brand",
      }
    ]}
  />
    <Spin indicator={<LottieLoading tip="Retrieving your saved result from the universe" />} spinning={loading}>
    <Form
      {...layout}
      form={form}
      name="control-hooks"
      onFinish={validateOnFinish}
      style={{ marginTop: 10 }}
      initialValues={{config: {}}}
    >
      <Form.Item name="label" label="Label" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      {!!item && <Form.Item name="version" label="Identifier">
        <span>Version {item?._v} @ {item?.id}.{process.env.REACT_APP_ENV}</span>
      </Form.Item>}
      <Form.Item name="config" label="Configuration" rules={[{ required: true }]}>
        <JsonEditorInput />
      </Form.Item>
      <Form.Item {...tailLayout}>
        <Space>
          <Button type="primary" htmlType="submit" loading={submitting}>
            Submit
          </Button>
        </Space>
      </Form.Item>
    </Form>
    </Spin>
    {modalContextHolder}
  </>
};
export default BrandAdminForm;
