import { Divider } from "antd";
import React from "react";
import { DynamicInputField } from ".";


const DynamicInputDivider: React.FC<DynamicInputField> = (props) => {
    const {field} = props;
    const {label} = field;

    return (<Divider>{label}</Divider>);
  }

export default DynamicInputDivider;