import {
  CloseOutlined,
  DoubleLeftOutlined,
  RedoOutlined,
} from '@ant-design/icons';
import { Editor } from '@tinymce/tinymce-react';
import {
  Alert,
  Button,
  Col,
  Divider,
  Modal,
  Row,
  Space,
  Spin,
  Switch,
  Tooltip,
} from 'antd';
import { MessageInstance } from 'antd/es/message/interface';
import { DateTime } from 'luxon';
import Mark from 'mark.js';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { userManager } from '../../components/AuthWrapper/index';
import { sendFinalOutput } from '../../data/generateResult';
import { ResultStorageData } from '../../data/result';
import { fetchPpm } from '../../data/scrapeContent';
import { RootState } from '../../redux';
import ExecutionPlan from '../../utils/executionPlan';
import { removeNonClosedTags } from '../../utils/htmlFix';
import QualityCheck, { QualityCheckItem } from '../../utils/qualityCheck';
import { AiStep } from '../AiInterface';
import AiInterfaceResultCopy from './components/AiInterfaceResultCopy';
import AiInterfaceResultDetails from './components/AiInterfaceResultDetails';
import AiInterfaceResultQualityCheck from './components/AiInterfaceResultQualityCheck';
import AiInterfaceResultRegenerate from './components/AiInterfaceResultRegenerate';
import AiInterfaceResultSave from './components/AiInterfaceResultSave';

/*
 * AiInterfaceResult
 * Contains the third step of the process
 * Display a WYSIWYG editor with the result
 */

interface AiInterfaceResultProps {
  back?: () => void;
  cleanStart?: () => void;
  messageApi: MessageInstance;
  step?: AiStep;
  savedResult?: ResultStorageData;
}

enum LoadingStages {
  DONE = 0,
  STAGE_1 = 1,
  STAGE_2 = 2,
}

let auth = { token: '', organization: '' };

const AiInterfaceResult: React.FC<AiInterfaceResultProps> = (props) => {
  const { back, cleanStart, messageApi, step, savedResult } = props;
  const editorRef = useRef<any | null>(null);
  const executionRef = useRef<any | null>(null);
  const [modal, contextHolder] = Modal.useModal();
  const [showDebugWindow, setShowDebugWindow] = useState<boolean>(false);
  const [highlightKeywords, setHighlightKeywords] = useState<boolean>(false);
  const [debugData, setDebugData] = useState<any>({});
  const [qcRes, setQcRes] = useState<QualityCheck>();
  const [loading, setLoading] = useState<LoadingStages>(LoadingStages.DONE);
  const [highlighterType, setHighlighterType] = useState<string | null>(null);
  const [lastSavedDate, setLastSavedDate] = useState<DateTime>();
  const [lastChangedDate, setLastChangedDate] = useState<DateTime>();
  const elementRef = useRef<HTMLDivElement | null>(null);
  const uuidRef = useRef<string | null>(savedResult?.generationUuid || null);
  const initTextRef = useRef<string | null>(null);
  const settings = useSelector((state: RootState) => state.branding.settings);
  const { params } = useSelector((state: RootState) => state.generation);
  const dirty = useMemo(
    () =>
      lastChangedDate && (!lastSavedDate || lastSavedDate < lastChangedDate),
    [lastChangedDate, lastSavedDate],
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const showInitText = searchParams.get('_showInitText') === '1';
  const [startTime, setStartTime] = useState<number>();
  const [duration, setDuration] = useState(0);


  useHotkeys(
    'ctrl+d',
    () => {
      setShowDebugWindow(true);
      setDebugData(executionRef.current?.report);
    },
    {
      preventDefault: true,
    },
    [showDebugWindow],
  );

  const brand = useSelector((state: RootState) => state.branding.brand);

  const internalLinks = useMemo(
    () => (settings?.brand_specific_input_fields
      ?.filter(i => i.type === "brandList")
      ?.map(link => (Array.isArray(link.name) ? link.name : [link.name])
      // @ts-ignore
      ?.reduce((acc: any, key: string) => acc && acc[key], params as any))
      || [])
      .flat(), [params],
  );

  useEffect(() => {
    if (highlightKeywords) markSecondaryKeywords();
    else clearMark();
  }, [highlightKeywords]);

  const handleFocus = () => {
    setStartTime(Date.now());
  };

  const handleBlur = () => {
    if (startTime) {
      const endTime = Date.now();
      const activeDuration = endTime - startTime;
      setDuration((previousDuration) => previousDuration + activeDuration);
      setStartTime(undefined); // Reset startTime for the next focus period
    }
  };

  const beforeunloadHandler = useCallback(
    function (e: any) {
      if ((!step || step === AiStep.RESULT) && dirty) {
        e.preventDefault();
        e.returnValue = '';
      }
    },
    [dirty],
  );

  useEffect(() => {
    window.addEventListener('beforeunload', beforeunloadHandler);
    return () => {
      window.removeEventListener('beforeunload', beforeunloadHandler);
    };
  }, [beforeunloadHandler]);

  userManager.getUser().then((user) => {
    if (user) {
      auth.token = user.access_token;
      if (brand) {
        auth.organization = brand?.id;
      }
    }
  });

  const updateContentFromStream = (value: [string, string]) => {
    const [, finalProcessed] = value;
    if (value) {
      setLoading(LoadingStages.STAGE_2);
    }
    const total = removeNonClosedTags(finalProcessed);
    try {
      editorRef.current?.setContent(total);

      // set init text for saving
      initTextRef.current = total;
    } catch (e) {
      console.error('failed setting content', e);
    }
    check(total);
    setLastChangedDate(DateTime.now());
  };

  const fetchFastResultsLocal = async () => {
    let localParams = params;

    // generate uuid to refer accross analytics
    uuidRef.current = uuidv4();

    setLoading(LoadingStages.STAGE_1);

    if (settings?.hasPpmCapability) {
      const ppmRes = await fetchPpm(localParams);
      localParams = {
        ...localParams,
        brandSpecific: {
          ...localParams.brandSpecific,
          ...ppmRes,
        },
      };
    }

    executionRef.current = new ExecutionPlan(
      settings?.executionPlan as any,
      localParams,
      auth,
      settings,
      uuidRef.current,
      internalLinks,
    );
    try {
      executionRef.current.checkFeasibility();
      await executionRef.current.executePrompts(updateContentFromStream);

      const [output, processedOutput] = executionRef.current.getOutput();

      // for statistics purpose, send final output to backend
      sendFinalOutput({
        output,
        processedOutput,
        params,
        uuid: uuidRef.current,
      });
    } catch (e) {
      messageApi.error('Execution failed: ' + e);
    }

    setLoading(LoadingStages.DONE);
  };

  const reExecutePrompt = async (id: number) => {
    // confirm override (if text was changed)
    if (!!lastChangedDate) {
      const ok = await modal.confirm({
        title: 'Override changes',
        content:
          'Regeneration will only affect the selected section; however, all other sections will revert to their initially generated texts. Prompt chains (e.g. prompts using the output of new-generated prompt) are not considered.',
      });
      if (!ok) return;
    }

    // check if execution ref is available
    if (!executionRef.current) {
      messageApi.error(
        'Execution is limited to the same session in which the generation occurred.',
      );
      return;
    }

    // execute
    setLoading(LoadingStages.STAGE_2);
    try {
      const prompt = settings?.executionPlan.prompts.find((i) => i.id === id);
      await executionRef.current.executeTemplatePrompt(
        prompt,
        updateContentFromStream,
      );
    } catch (e) {
      messageApi.error('Execution failed: ' + e);
    }
    setLoading(LoadingStages.DONE);
  };

  // every time results are changed from parent, update editor
  useEffect(() => {
    if (savedResult) {
      const res = showInitText ? savedResult.initText : savedResult.text;
      if (savedResult.editDuration) setDuration(savedResult.editDuration);
      editorRef.current?.setContent(res);
      check(res!);
    }
  }, [savedResult, settings, showInitText]);

  useEffect(() => {
    if (!step) return;

    if (step !== AiStep.RESULT) {
      setLoading(LoadingStages.DONE);
    } else {
      editorRef.current?.setContent('');
      fetchFastResultsLocal();
    }
  }, [step]);

  const check = (val: string) => {
    if (elementRef.current) elementRef.current.innerHTML = val;

    const qc = new QualityCheck(
      val,
      elementRef!.current!,
      settings,
      params.primaryKeyword!,
      params.brandSpecific?.keywords
        ?.filter((x: any) => x.category === 'used')
        ?.map((x) => x.label) || [],
      params.brandSpecific?.faqs?.map((x) => x.label) || [],
      internalLinks,
      brand!.id,
      params
    );
    setQcRes(qc);
  };

  const getMarkInstance = () => {
    const marker = new Mark(editorRef.current.dom.doc);
    const options: Mark.MarkOptions = {
      each: (element) => {
        element.setAttribute('data-mce-bogus', '1');
        element.setAttribute('data-marker', '1');
      },
    };
    return { marker, options };
  };

  const mark = (item: QualityCheckItem) => {
    if (!editorRef.current?.dom) return;
    clearMark();
    const { marker, options } = getMarkInstance();
    item.highlight?.(marker, options);
    setHighlighterType(item.title);
  };

  const clearMark = (removeHighlightKeywordsFlag = true) => {
    if (!editorRef.current?.dom) return;
    const marker = new Mark(editorRef.current.dom.doc);
    marker.unmark();
    setHighlighterType(null);
    if (removeHighlightKeywordsFlag) setHighlightKeywords(false);
  };

  const markSecondaryKeywords = () => {
    if (!editorRef.current?.dom) return;
    clearMark(false);
    const { marker, options } = getMarkInstance();
    const newOptions: Mark.MarkOptions = {
      ...options,
      acrossElements: true,
      className: 'keyword-higlighter',
    };
    const escapedKeywords = params.brandSpecific?.keywords
      ?.filter((i) => i.category === 'used')
      .map((s) => s.label.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')) || [''];
    const regex = new RegExp(escapedKeywords.join('|'), 'i');
    marker.markRegExp(regex, newOptions);
  };

  return (
    <div style={{ marginTop: 40 }}>
      <Row wrap={false} style={{ marginBottom: 10 }}>
        <Col flex="auto">
          {loading !== 0 ? (
            <Alert
              type="info"
              message="Your text is currently beeing generated. Previewed format (e.g. bolding, lists) is not final yet."
              closable
              style={{ marginRight: 25 }}
              showIcon
            />
          ) : (
            <>
              {showInitText && (
                <Alert
                  type="info"
                  message="Show first saved variant"
                  style={{ marginRight: 25 }}
                  showIcon
                />
              )}

              {true && (
                <Tooltip title="Keywords are highlighted for preview only - highlighting is not included in HTML copy">
                  <Space>
                    <Switch
                      size="small"
                      checked={highlightKeywords}
                      onChange={(active) => setHighlightKeywords(active)}
                    />
                    <span
                      onClick={() => setHighlightKeywords(!highlightKeywords)}
                    >
                      Highlight keywords
                    </span>
                  </Space>
                </Tooltip>
              )}
              {!!highlighterType && (
                <Space>
                  <Divider type="vertical" />
                  <Tooltip title="Leave highlight mode">
                    <Button
                      icon={<CloseOutlined />}
                      size="small"
                      onClick={() => clearMark()}
                    />
                  </Tooltip>
                  <span>
                    Highlighting quality check issue for{' '}
                    <i>{highlighterType}</i>
                  </span>
                </Space>
              )}
            </>
          )}
        </Col>
        <Col flex="none">
          <Space style={{ paddingTop: 5, paddingBottom: 5 }}>
            {!!back && (
              <Button
                htmlType="button"
                onClick={() => {
                  back();
                }}
                icon={<DoubleLeftOutlined />}
              >
                Back
              </Button>
            )}
            {!!cleanStart && (
              <Button
                htmlType="button"
                onClick={() => {
                  cleanStart();
                }}
                icon={<RedoOutlined />}
              >
                Start over
              </Button>
            )}
            {!!step && (
              <AiInterfaceResultRegenerate
                loading={loading !== LoadingStages.DONE}
                reExecutePrompt={reExecutePrompt}
              />
            )}
            <AiInterfaceResultCopy
              messageApi={messageApi}
              editorRef={editorRef}
              elementRef={elementRef}
              uuidRef={uuidRef}
              loading={loading !== LoadingStages.DONE}
            />
          </Space>
        </Col>
      </Row>
      <Row
        gutter={[16, 24]}
        style={{ display: 'flex', height: '500px', marginBottom: 10 }}
      >
        <Col span={18}>
          <Spin
            spinning={loading === LoadingStages.STAGE_1}
            tip="Preparing generation"
          >
            <Editor
              onInit={(evt, editor) => (editorRef.current = editor)}
              onKeyDown={() => setLastChangedDate(DateTime.now())}
              initialValue="Loading..."
              onEditorChange={(val) => {
                check(val);
              }}
              onFocus={handleFocus}
              onBlur={handleBlur}
              textareaName="seo-editor"
              disabled={!!loading}
              init={{
                height: 500,
                menubar: false,
                plugins: [
                  'lists',
                  'advlist',
                  'link',
                  'image',
                  'searchreplace',
                  'fullscreen',
                  'wordcount',
                  'autolink',
                  'searchreplace',
                  'code',
                ],
                toolbar:
                  'undo redo | h1 h2 h3 | formatselect | code |' +
                  'bold italic backcolor | bullist numlist outdent indent | ' +
                  'removeformat searchreplace | ' +
                  'wordcount',
                content_style:
                  'body { font-family:Helvetica,Arial,sans-serif; font-size:14px } dt { font-weight: bold; margin-top: 1em } dd { margin: 0; }',
              }}
            />
          </Spin>
        </Col>
        <Col span={6} style={{ overflowY: 'auto', height: '100%' }}>
          <Spin spinning={!!loading}>
            <Space direction="vertical" style={{ width: '100%' }}>
              {showDebugWindow && <p>Debug: {JSON.stringify(debugData)}</p>}
              {!showInitText && (
                <AiInterfaceResultSave
                  editorRef={editorRef}
                  initTextRef={initTextRef}
                  params={params}
                  loading={loading !== LoadingStages.DONE || showInitText}
                  savedResult={savedResult}
                  editDuration={duration}
                  uuidRef={uuidRef}
                  onSave={(date) => setLastSavedDate(date)}
                />
              )}
              <AiInterfaceResultQualityCheck
                qcRes={qcRes}
                mark={mark}
                clearMark={clearMark}
                highlighterType={highlighterType}
              />
            </Space>
          </Spin>
        </Col>
      </Row>
      {(settings?.showInputOnResultPage || showDebugWindow) && (
        <AiInterfaceResultDetails 
        
        savedResult={savedResult}
        />
      )}
      <div style={{ display: 'none' }} ref={elementRef} />
      {contextHolder}
    </div>
  );
};
export default AiInterfaceResult;
