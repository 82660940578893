export function centralTransformHtml(doc: HTMLDivElement) {
    // The transformed content will be accumulated here
    let transformedContent = '';
    let intro = '';
    let seoContent = '';
    let faqContent = '';
  
    // Intro Content (unchanged)
    intro += '<!-- Intro Content -->\n';
    const firstH2 = doc.querySelector('h2');
    if (firstH2) {
      const introContent = doc.innerHTML.substring(0, doc.innerHTML.indexOf(firstH2.outerHTML));
      intro += introContent;
    }
  
    // SEO Content (manipulate as necessary, example skipped for brevity)
    if (firstH2) {
        seoContent += '<!-- SEO Content -->\n';
        seoContent += `<h3>${firstH2.textContent}</h3>\n<div class="seo-desc">\n`;
        let nextElement = firstH2.nextElementSibling;
        while(nextElement && nextElement.tagName !== 'H2') {
        if (nextElement.tagName === 'P' || nextElement.tagName === 'DL') {
          seoContent += `    ${nextElement.outerHTML}\n`;
        }
        nextElement = nextElement.nextElementSibling;
        }
        seoContent += '</div>\n</br>\n';
    }
    // FAQ Content
    const faqsH2 = doc.querySelectorAll('h2')[1]; // Assuming the FAQs are always under the second <h2>
    if (faqsH2) {
      const faqTitle = faqsH2.textContent;
      const faqIntroP = faqsH2.nextElementSibling && faqsH2.nextElementSibling.tagName === 'P' ? faqsH2.nextElementSibling.outerHTML : '';
      faqContent += `<!-- FAQ Content -->\n`;
      faqContent += `<h4>${faqTitle}</h4>\n`;
      faqContent += `${faqIntroP}\n`;
      faqContent += `<div class="seo-accordion">\n`;
      
      // Now process each FAQ item
      const faqDL = faqsH2.nextElementSibling?.tagName === 'P' ? faqsH2.nextElementSibling.nextElementSibling : faqsH2.nextElementSibling;
      if (faqDL && faqDL.tagName === 'DL') {
        const dtElements = faqDL.querySelectorAll('dt');
        dtElements.forEach((dt) => {
          const question = dt.textContent;
          const answer = dt.nextElementSibling && dt.nextElementSibling.tagName === 'DD' ? dt.nextElementSibling.innerHTML : '';
    
          faqContent += `    <div data-role="collapsible">\n`;
          faqContent += `        <div data-role="trigger">\n`;
          faqContent += `            <span>${question}</span>\n`;
          faqContent += `        </div>\n`;
          faqContent += `        <div data-role="content">\n`;
          faqContent += `            <p>${answer}</p>\n`;
          faqContent += `        </div>\n`;
          faqContent += `    </div>\n`;
        });
        faqContent += `</div>\n`;
      }
    }
  
    return {
      transformedContent: intro + seoContent + faqContent,
      intro,
      seoContent,
      faqContent,
      sections: [intro, seoContent, faqContent]
    };
  }
