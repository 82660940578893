import request from '../utils/request';

/*
 * Category data
 * Interfaces and fetch functionality for the category list (first and second level)
 */

export interface ISecondLevelCategory {
  id: string;
  name: string;
  parentCategoryId: string;
  //scrapeUrl?: string[];
  //scrapeUrlDisabled?: boolean;
}

interface IFirstLevelCategory {
  id: string;
  name: string;
  level: number;
  parent_category_id?: string;
  link: string;
}

export interface ICategoryList {
  categories: IFirstLevelCategory[];
}

export const fetchCategoryList = async () => {
  const res = await request<ICategoryList>('/categories', {
    method: 'get',
  });
  return res;
};
