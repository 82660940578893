import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import BrandList, {
  BrandListItem,
  fetchBrandSettings,
  IBrand,
  IBrandSettings,
  IUserSettings
} from '../data/brands';
import { LocalStorageKeys, localStorageSet } from '../utils/localStorage';

export interface IBrandingState {
  brand: BrandListItem | null;
  availableBrands: BrandListItem[];
  settings: IBrandSettings | undefined;
  brandAuth: IUserSettings | undefined;
  data?: IBrand;
  isMockMode: boolean;
}

const initialState: IBrandingState = {
  brand: null,
  availableBrands: [],
  brandAuth: undefined,
  data: undefined,
  isMockMode: false,
  settings: undefined,
};

export const setBrand = createAsyncThunk('branding/setBrand', async (name: string) => {
  const settings = await fetchBrandSettings(name);
  localStorageSet(LocalStorageKeys.BRAND, name);
  return {
    name,
    settings: settings.settings,
    brandAuth: settings.user
  }
})


const slice = createSlice({
  name: 'branding',
  initialState,
  reducers: {
    /*setBrand: (state, action: PayloadAction<string | null>) => {
      state.brand = action.payload;
      state.data = BrandList.find((i) => i.name === action.payload);
    },*/
    setAvailableBrands: (state, action: PayloadAction<BrandListItem[]>) => {
      state.availableBrands = action.payload;
    },
    setMockMode: (state, action: PayloadAction<boolean>) => {
      state.isMockMode = action.payload;
    },
    /*setBrandSettings: (state, action: PayloadAction<IBrandSettings>) => {
      state.settings = action.payload;
    },*/
  },
  extraReducers(builder) {
    builder
      .addCase(setBrand.fulfilled, (state, action: any) => {
        state.brand = state.availableBrands.find(i => i.id === action.payload.name) || null;
        state.data = BrandList.find((i) => i.name === action.payload.name);
        state.settings = action.payload.settings
        state.brandAuth = action.payload.brandAuth
      })
  }
});

export const { setMockMode, setAvailableBrands } =
  slice.actions;

export default slice.reducer;