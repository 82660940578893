/*
 * Helper to convert flat category levels (as seen in relational databases) into ant design tree select format (nested)
 * for source format see interface Category 
 * for target format see https://ant.design/components/tree-select/
 */

// format as seen in database
interface Category {
  id: string;
  level: number;
  link: string;
  name: string;
  parent_category_id: string | null;
}

// format as seen in ant design
interface MappedCategory {
  value: string;
  title: string;
  children: MappedCategory[];
}

export function mapCategories(inputCategories: Category[]): MappedCategory[] {
  if (!inputCategories) return [];
  let categoryMap: Map<string, MappedCategory> = new Map();

  // First, map all categories by their IDs
  inputCategories.forEach((category: Category) => {
    categoryMap.set(category.id, {
      value: category.id,
      title: category.name,
      children: [],
    });
  });

  // Now, establish parent-child relationships
  inputCategories.forEach((category: Category) => {
    if (category.parent_category_id) {
      let parentCategory: MappedCategory | undefined = categoryMap.get(
        category.parent_category_id,
      );
      if (parentCategory) {
        parentCategory.children.push(categoryMap.get(category.id)!);
      }
    }
  });

  // Extract the top-level categories
  let topLevelCategories: MappedCategory[] = inputCategories
    .filter((category: Category) => category.level === 1)
    .map((category: Category) => categoryMap.get(category.id)!);

  return topLevelCategories;
}
