import { CheckCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { Button, List, Modal, Space, Tag, Tooltip, Typography } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux';
import { setBrand } from '../../redux/branding';
import { useNavigate } from 'react-router-dom';

const BrandSwitcher: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { brand, settings, isMockMode, availableBrands } = useSelector(
    (state: RootState) => state.branding,
  );
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  // hide if less than one brand available
  if (availableBrands.length <= 1) return null;

  if (isMockMode) return null;
  return (
    <>
      <Button type="link" onClick={() => setIsModalOpen(true)}>
        <Tooltip title={'Current active brand: ' + brand?.label}>
          <Tag color="cyan">{brand?.label}</Tag>
        </Tooltip>
      </Button>

      <Modal
        title="Select brand"
        open={isModalOpen}
        okButtonProps={{ style: { display: 'none' } }}
        onCancel={() => setIsModalOpen(false)}
      >
        <Typography.Text type="secondary">Please choose the brand to generate text for. Please note, that access could be limited by your organization and account.</Typography.Text>
        <List
          bordered
          dataSource={availableBrands}
          style={{marginTop: 10}}
          renderItem={(item) => (
            <List.Item
              style={{paddingLeft: 0}}
              onClick={() => {
                dispatch(setBrand(item.id));
                setIsModalOpen(false);
                navigate('/');
              }}
              extra={<Space>
                {brand?.id === item.id && (
                  <Tooltip title="Active brand">
                    <CheckCircleOutlined style={{color: "green"}} />
                  </Tooltip>
                )}
                {item?.isLegacy === true && (
                  <Tooltip title="Legacy V1 configuration">
                    <WarningOutlined />
                  </Tooltip>
                )}
                </Space>
              }
            >
              <Button type="link">{item.label}</Button>
            </List.Item>
          )}
        />
      </Modal>
    </>
  );
};

export default BrandSwitcher;
