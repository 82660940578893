import {
  CheckOutlined,
  EyeOutlined,
  QuestionCircleOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { Button, Col, Divider, List, Row, Space, Table, Tooltip, Typography } from 'antd';
import React from 'react';
import QualityCheck, {
  InternalLinkIssue,
  InternalLinkIssueType,
  QualityCheckItem,
} from '../../../utils/qualityCheck';
import type { TableProps } from 'antd';
import { StringDiff } from 'react-string-diff';

interface AiInterfaceResultQualityCheckLinkDetailProps {
  item: QualityCheckItem;
}

const issueToLanguage = {
  [InternalLinkIssueType.ADDITIONAL_LINK]: 'Link not provided in input',
  [InternalLinkIssueType.SUBMITTED_LINK_NOT_INCLUDED]: 'Link from input unused',
  [InternalLinkIssueType.SUBMITTED_LINK_WRONG_LINK]:
    'Link differentiates from input',
  [InternalLinkIssueType.SUBMITTED_LINK_WRONG_TITLE]:
    'Name differentiates from input',
};

const AiInterfaceResultQualityCheckLinkDetail: React.FC<
  AiInterfaceResultQualityCheckLinkDetailProps
> = (props) => {
  const { item } = props;

  const columns: TableProps<InternalLinkIssue>['columns'] = [
    {
      title: 'Reason',
      dataIndex: 'type',
      key: 'type',
      render: (_, record) => issueToLanguage[record.type],
    },
    {
      title: 'Name',
      dataIndex: 'nameShouldBe',
      key: 'nameShouldBe',
      render: (_, record) => {
        //if (record.type !== InternalLinkIssueType.SUBMITTED_LINK_WRONG_TITLE) return record.nameAsIs;
        return (
          <Space direction="vertical">
            {!!record.nameAsIs && !record.nameShouldBe && (
              <span>Current: {record.nameAsIs}</span>
            )}
            {!!record.nameAsIs && !!record.nameShouldBe && (
              <span>
                Current:{' '}
                <StringDiff
                  oldValue={record.nameShouldBe!}
                  newValue={record.nameAsIs!}
                />
              </span>
            )}
            {!!record.nameShouldBe && (
              <span>
                Input:{' '}
                <Typography.Text copyable>
                  {record.nameShouldBe}
                </Typography.Text>
              </span>
            )}
          </Space>
        );
      },
    },
    {
      title: 'Link',
      dataIndex: 'address',
      key: 'linkShouldBe',
      render: (_, record) => {
        //if (record.type !== InternalLinkIssueType.SUBMITTED_LINK_WRONG_LINK) return record.linkAsIs;
        return (
          <Space direction="vertical">
            {!!record.linkAsIs && !record.linkShouldBe && (
              <span>Current: {record.linkAsIs}</span>
            )}
            {!!record.linkAsIs && !!record.linkShouldBe && (
              <span>
                Current:{' '}
                <StringDiff
                  oldValue={record.linkShouldBe!}
                  newValue={record.linkAsIs!}
                />
              </span>
            )}
            {!!record.linkShouldBe && (
              <span>
                Input:{' '}
                <Typography.Text copyable>
                  {record.linkShouldBe}
                </Typography.Text>
              </span>
            )}
          </Space>
        );
      },
    },
  ];

  return <>
    <Table dataSource={item.details} columns={columns} />
    <Row justify="start" align="middle">
      <Col span={4}>
        Current text legend:
      </Col>
      <Col span={4}>
        <StringDiff oldValue="Removed text" newValue="" />
      </Col>
      <Col span={4}>
      <StringDiff oldValue="" newValue="Added text" />
      </Col>
    </Row>
  </>
};
export default AiInterfaceResultQualityCheckLinkDetail;
