import Ajv from "ajv";
import { Breadcrumb, Col, Form, Modal, Result, Row, Select, SelectProps, Spin, Typography, message } from 'antd';
import React, { useEffect, useState } from 'react';
import ReactDiffViewer from 'react-diff-viewer-refined';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { brandAdminGetHistory } from '../../data/brandAdmin';
import { IBrandRecord } from '../../data/brands';
import LottieLoading from '../../lottie/LottieLoading';
import { AppDispatch, RootState } from '../../redux';
import NotFoundItem from '../NotFoundItem';

import '../styles.css';

const ajv = new Ajv({allErrors: true});

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 29 },
};

const tailLayout = {
  wrapperCol: { offset: 4, span: 20 },
};

const removeSystemAttributes = (originalObject: any) => Object.fromEntries(
  Object.entries(originalObject)?.filter(([key]) => !key.startsWith('_') && !["id", "brand_id", "timestamp_created", "timestamp_updated", "user"].includes(key))
);

const BrandAdminHistory: React.FC = () => {
  const { id } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();
  const [messageApi, contextHolder] = message.useMessage();
  const [modal, modalContextHolder] = Modal.useModal();
  const [loading, setLoading] = useState<boolean>(false);
  const [itemLeft, setItemLeft] = useState<string>();
  const [itemRight, setItemRight] = useState<string>();
  const [showNotFound, setShowNotFound] = useState<boolean>(false);
  const [items, setItems] = useState<IBrandRecord[]>([]);
  const branding = useSelector((state: RootState) => state.branding.brand);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [form] = Form.useForm();


  const loadItem = async () => {
    setLoading(true)
    try {
      const res = await brandAdminGetHistory(id!);
      setItems(res?.list)
    }
    catch (e) {
      setShowNotFound(true)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (!id) return;

    setShowNotFound(false)
    loadItem();
  }, [id, branding])
  
  if (showNotFound) return <NotFoundItem />

  const SelectVersion = (props: SelectProps) => <Select
  style={{width: "100%"}}
  showSearch
  placeholder="Select a version"
  optionFilterProp="label"
  allowClear
  {...props}
  options={items?.map((item: any) => ({ label: "Version "+item._v +" - "+ item.timestamp_created, value: item.id }))}
/>

  return <>
    {contextHolder}
    <Breadcrumb
    items={[
      {
        title: 'Home',
        onClick: () => navigate("/")
      },
      {
        title: "Brand administration",
        onClick: () => navigate("/brand-admin")
      },
      {
        title: "Brand history",
      }
    ]}
  />
    <Spin indicator={<LottieLoading tip="Retrieving your saved result from the universe" />} spinning={loading}>

    <Row gutter={[16, 16]} style={{marginTop: 10, marginBottom: 10}}>
      <Col span={12}>
        <Row gutter={[16, 16]} align="middle">
          <Col flex="none">Left window <Typography.Text copyable={{ text: JSON.stringify(removeSystemAttributes(items.find(i => i.id === itemLeft) || {})) }} /></Col>
          <Col flex="auto">{items?.length ? <SelectVersion onChange={(val) => setItemLeft(val)} value={itemLeft}/> : <Typography.Text type="secondary">No version history found</Typography.Text>}</Col>
        </Row>
      </Col>
      <Col span={12}>
        <Row gutter={[16, 16]} align="middle">
          <Col flex="none">Right window <Typography.Text copyable={{ text: JSON.stringify(removeSystemAttributes(items.find(i => i.id === itemRight) || {})) }} /></Col>
          <Col flex="auto">{items?.length ? <SelectVersion onChange={(val) => setItemRight(val)} value={itemRight}/> : <Typography.Text type="secondary">No version history found</Typography.Text>}</Col>
        </Row>
      </Col>
    </Row>

    {!itemLeft || !itemRight ?   <Result
    status="404"
    title="Select versions to compare"
    subTitle="Please use the select function to compare different versions"
  /> : <div style={{maxWidth: "100%"}}>
      <ReactDiffViewer 
        oldValue={JSON.stringify(removeSystemAttributes(items.find(i => i.id === itemLeft) || {}))} 
        newValue={JSON.stringify(removeSystemAttributes(items.find(i => i.id === itemRight) || {}))} 
        splitView={true} 
      />
    </div>}

    </Spin>
    {modalContextHolder}
  </>
};
export default BrandAdminHistory;
