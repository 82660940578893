import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AiInterfaceCategoryForm } from '../pages/components/AiInterfaceCategory';
import { AiInterfaceDetailsForm } from '../pages/components/AiInterfaceDetails';
import { AiInterfaceScrapeUrlForm } from '../pages/components/AiInterfaceKeyword';
import { IGenerateResult } from '../data/generateResult';
import { IScrapeRequestResult } from '../data/scrapeUrls';
import { IKeyword } from '../data/scrapeResult';

type IGenerationProcessStateParams = Partial<
  AiInterfaceCategoryForm & AiInterfaceDetailsForm & AiInterfaceScrapeUrlForm
>;

export interface IGenerationProcessState {
  params: IGenerationProcessStateParams;
  scrapeUrls: Partial<IScrapeRequestResult>;
  generateResults: Partial<IGenerateResult>;
  content: any;
  keywords: IKeyword[];
  faqs: IKeyword[];
}

const initialState: IGenerationProcessState = {
  params: {},
  scrapeUrls: {},
  keywords: [],
  faqs: [],
  content: {},
  generateResults: {},
};

const slice = createSlice({
  name: 'branding',
  initialState,
  reducers: {
    setParams: (
      state,
      action: PayloadAction<IGenerationProcessStateParams>,
    ) => {
      state.params = {
        ...state.params,
        ...action.payload,
      };
    },
    setScrapeUrls: (
      state,
      action: PayloadAction<Partial<IScrapeRequestResult>>,
    ) => {
      state.scrapeUrls = action.payload;
    },
    setContent: (state, action: PayloadAction<any>) => {
      state.content = action.payload;
    },
    setKeywords: (state, action: PayloadAction<IKeyword[]>) => {
      state.keywords = action.payload;
    },
    setFaqs: (state, action: PayloadAction<IKeyword[]>) => {
      state.faqs = action.payload;
    },
    setGenerateResults: (
      state,
      action: PayloadAction<Partial<IGenerateResult>>,
    ) => {
      state.generateResults = action.payload;
    },
  },
});

export const {
  setParams,
  setScrapeUrls,
  setKeywords,
  setGenerateResults,
  setContent,
  setFaqs,
} = slice.actions;

export default slice.reducer;
