import { Button, Result } from "antd";
import { useNavigate } from 'react-router-dom'

const NotFoundItem = () => {
    const navigate = useNavigate()

    return (
        <Result
        status="404"
        title="Could not retrieve result"
        subTitle="Sorry, the result you requested does not exist or you do not have the required permissions."
        extra={<Button type="primary" onClick={() => navigate("/")}>Back Home</Button>}
      />
    )
}
export default NotFoundItem;