import { PlusOutlined, SyncOutlined } from '@ant-design/icons';
import ProTable, { ProColumns } from '@ant-design/pro-table';
import { Alert, Breadcrumb, Button, Modal, Tooltip, message } from 'antd';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { brandAdminDelete, brandAdminList } from '../../data/brandAdmin';
import { IBrandRecord } from '../../data/brands';
import { RootState } from '../../redux';
import '../styles.css';
import AdminBrandPromoteModal from './AdminBrandPromoteModal';


const BrandAdminList: React.FC = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [modal, modalContextHolder] = Modal.useModal();
  const [loading, setLoading] = useState<boolean>(true);
  const [promoteModalVisible, setPromoteModalVisible] = useState<boolean>(false);
  const { brandAuth } = useSelector((state: RootState) => state.branding);
  const [data, setData] = useState<IBrandRecord[]>([]);
  const navigate = useNavigate();


  const deleteBrand = async (brand: IBrandRecord) => {
    modal.confirm({
      title: 'Are you sure?',
      content: "Are you sure you want to delete the brand '" + brand.label + "'?",
      onOk: async () => {
        try {
          await  brandAdminDelete(brand.id)
          messageApi.success("Brand "+brand.label+" deleted successfully")
          loadData()
          return true;
        } catch (e) {
          message.error(
            'Could not delete brand. Please try again or contact the support.',
          );
          return false;
        }
      }
    });
  }

  const loadData = async () => {
    setLoading(true);
    const res: any = await brandAdminList();
    setData(res.list);
    setLoading(false);
  };

  // always reload list on modal close (could be that promote was triggered)
  // also handles inital fetch
  useEffect(() => {
    if (!promoteModalVisible) loadData();
  }, [promoteModalVisible]);


  const columns: ProColumns<IBrandRecord>[] = [
    {
      title: 'Name',
      key: "name",
      dataIndex: 'label',
      render: (_, it) => (
        <a>
          {_}
        </a>
      ),
      sorter: (a, b) => a.label?.localeCompare(b.label || ''),
    },
    {
      title: 'Version',
      dataIndex: '_v',
      tooltip: "Version is unique in every environment and will increment on update"
    },
    {
      title: 'Handler',
      dataIndex: 'classFile',
      tooltip: "Handler implements additional functionality in the backend"
    },
    {
      title: '# Prompts',
      dataIndex: 'label',
      render: (_, it) => (Object.keys(it)?.filter(key => key.startsWith('prompt'))?.length)
    },
    {
      title: '# Orgs',
      dataIndex: 'label',
      render: (_, it: any) => (
        <Tooltip title={it?.organizations?.join(', ')}>
          {it?.organizations?.length}
        </Tooltip>
      )
    },
    {
      title: '# QCs',
      dataIndex: 'label',
      render: (_, it: any) => (
        <Tooltip title={it?.public_settings?.qualityCheck?.map((i: any) => i.module)?.join(', ')}>
          {it?.public_settings?.qualityCheck?.length}
        </Tooltip>
      )
    },
    {
      title: '# Fields',
      dataIndex: 'label',
      render: (_, it: any) => (
        <Tooltip title={it?.public_settings?.brand_specific_input_fields?.map((i: any) => i.label)?.join(', ')}>
          {it?.public_settings?.brand_specific_input_fields?.length}
        </Tooltip>
      )
    },
    {
      title: '# Languages',
      dataIndex: 'label',
      render: (_, it: any) => (
        <Tooltip title={it?.public_settings?.languages?.join(', ')}>
          {it?.public_settings?.languages?.length}
        </Tooltip>
      )
    },
    {
      title: '# LLMs',
      dataIndex: 'label',
      render: (_, it: any) => (
        <Tooltip title={it?.public_settings?.model?.map((i: any) => i.name)?.join(', ')}>
          {it?.public_settings?.model?.length}
        </Tooltip>
      )
    },
    {
      title: '# Tones',
      dataIndex: 'label',
      render: (_, it: any) => (
        <Tooltip title={it?.public_settings?.tone?.map((i: any) => i.name)?.join(', ')}>
          {it?.public_settings?.tone?.length}
        </Tooltip>
      )
    },
    {
      title: 'Created',
      dataIndex: 'datetime_created',
      valueType: 'dateRange',
      search: {
        transform: (value) => {
          return {
            startTime: value[0],
            endTime: value[1],
          };
        },
      },
      render: (_, it) =>
        _
          ? DateTime.fromISO(it.timestamp_created!).toLocaleString(
            DateTime.DATETIME_FULL,
          )
          : ' - ',
      sorter: (a, b) =>
        DateTime.fromISO(a.timestamp_created || '').toMillis() -
        DateTime.fromISO(b.timestamp_created || '').toMillis(),
    },
    {
      title: 'Edited',
      dataIndex: 'timestamp_updated',
      valueType: 'date',
      hideInSearch: true,
      render: (_, it) =>
        _
          ? DateTime.fromISO(it.timestamp_updated!).toLocaleString(
            DateTime.DATETIME_FULL,
          )
          : ' - ',
      sorter: (a, b) =>
        DateTime.fromISO(a.timestamp_updated || '').toMillis() -
        DateTime.fromISO(b.timestamp_updated || '').toMillis(),
    },
    {
      title: 'Options',
      key: 'option',
      hideInSearch: true,
      valueType: 'option',
      width: 50,
      render: (_, it) => [
        <Button
        key="clone"
        type="link"
        onClick={(e) => {
          navigate('/brand-admin/history/' + it.id)
          e.stopPropagation();
        }}
      >
        History
      </Button>,
        <Button
        key="clone"
        type="link"
        onClick={(e) => {
          navigate('/brand-admin/' + it.id)
          e.stopPropagation();
        }}
      >
        Edit
      </Button>,
        <Button
        key="clone"
        type="link"
        onClick={(e) => {
          navigate('/brand-admin/create?baseBrand=' + it.id)
          e.stopPropagation();
        }}
      >
        Duplicate
      </Button>,
        <Button
          key="delete"
          danger
          type="link"
          onClick={(e) => {
            deleteBrand(it)
            e.stopPropagation();
          }}
        >
          Delete
        </Button>
      ],
    },
  ];

  const RestrictedAdminNotice = () => <Alert type="info"showIcon message="Your account has restricted administration previleges; only selected brands will be shown" style={{marginBottom: 10, marginTop: 10}}></Alert>

  return (
    <>
      {contextHolder}

      <Breadcrumb
        items={[
          {
            title: 'Home',
            onClick: () => navigate('/'),
          },
          {
            title: "Brand administration",
          },
        ]}
      />

      {brandAuth?.is_restricted_admin && <RestrictedAdminNotice />}

      <ProTable<IBrandRecord>
        rowKey="key"
        pagination={{
          showQuickJumper: true,
        }}
        columns={columns}
        search={false}
        onRow={(record) => {
          return {
            onClick: () => navigate('/brand-admin/' + record.id),
          };
        }}
        dataSource={data}
        loading={loading}
        dateFormatter="string"
        headerTitle="Brand list"
          toolBarRender={() => [
            <Button key="promote" onClick={() => setPromoteModalVisible(true)}>
              <SyncOutlined />
              Promote config
            </Button>,
            <Button key="add" type="primary" onClick={() => navigate("/brand-admin/create")}>
              <PlusOutlined />
              Add brand
            </Button>,
          ]
        }
      />
      <AdminBrandPromoteModal modalVisible={promoteModalVisible} setModalVisible={setPromoteModalVisible} />
      {modalContextHolder}
    </>
  );
};
export default BrandAdminList;
