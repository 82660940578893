import { AiInterfaceScrapeUrlForm } from '../pages/components/AiInterfaceKeyword';
import request from '../utils/request';

/*
 * Scrape result data
 * Interfaces and fetch functionality for the keywords based on scraping
 */

export enum KeywordCategory {
  USED = 'used',
  STORED = 'stored',
  REMOVED = 'removed',
}

export interface IKeyword {
  id?: string;
  label: string;
  category: KeywordCategory;
}

export interface IScrapeResult {
  keywords: IKeyword[];
}

export const fetchKeywords = async (data: AiInterfaceScrapeUrlForm) => {
  const res = await request<IScrapeResult>('/keywords', {
    method: 'post',
    data,
  });

  return res;
};
