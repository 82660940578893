import request from '../utils/request';

export interface ResultStorageData {
  name: string;
  text: string;
  initText?: string;
  params: any;
  id?: string;
  timestamp?: string;
  initTimestamp?: string;
  initUser?: string;
  user?: string;
  editDuration?: number;
  generationUuid?: string;
}

export const resultStorageList = async () => {
  const res = await request<Partial<ResultStorageData[]>>('/result/list', {
    method: 'get',
  });
  return res;
};

export const resultStorageCreate = async (data: ResultStorageData) => {
  const res = await request<Partial<ResultStorageData>>('/result/create', {
    method: 'put',
    data,
  });
  return res;
};

export const resultStorageSave = async (
  id: string,
  data: Partial<ResultStorageData>,
) => {
  const res = await request<Partial<ResultStorageData>>('/result/' + id, {
    method: 'patch',
    data,
  });
  return res;
};

export const resultStorageDelete = async (id: string) => {
  const res = await request<Partial<ResultStorageData>>('/result/' + id, {
    method: 'delete',
  });
  return res;
};

export const resultStorageGet = async (id: string) => {
  const res = await request<Partial<ResultStorageData>>('/result/' + id, {
    method: 'get',
  });
  return res;
};
