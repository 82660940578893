import { ConfigProvider } from 'antd';
import enUS from 'antd/locale/en_US';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import AuthWrapper from './components/AuthWrapper';
import AiLayout from './components/layout/AiLayout';
import { theme } from './config/design';
import BrandAdminForm from './pages/admin/AdminBrandForm';
import BrandAdminHistory from './pages/admin/AdminBrandHistory';
import BrandAdminList from './pages/admin/AdminBrandList';
import AiInterface from './pages/AiInterface';
import NotFound from './pages/NotFound';
import SavedItem from './pages/saved/SavedItem';
import SavedList from './pages/saved/SavedList';
import { store } from './redux';

const App: React.FC = () => {
  return (
    <ConfigProvider theme={theme} locale={enUS}>
      <Provider store={store}>
        <AuthWrapper>
          <BrowserRouter>
            <AiLayout>
              <Routes>
                <Route path="/" element={<AiInterface />} />
                <Route path="/generate/:urlStep" element={<AiInterface />} />
                <Route path="/brand-admin" element={<BrandAdminList />} />
                <Route path="/brand-admin/history/:id" element={<BrandAdminHistory />} />
                <Route path="/brand-admin/:id" element={<BrandAdminForm />} />
                <Route path="/saved" element={<SavedList />} />
                <Route path="/saved/:id" element={<SavedItem />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </AiLayout>
          </BrowserRouter>
        </AuthWrapper>
      </Provider>
    </ConfigProvider>
  );
};

export default App;
