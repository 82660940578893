import {
  KeyOutlined,
  LogoutOutlined,
  ProfileOutlined,
  UserOutlined,
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Avatar, Button, Dropdown, Layout, Menu, Space, Tag, Tooltip, theme } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { customization } from '../../config/customization';
import { RootState } from '../../redux';
import { userManager } from '../AuthWrapper';
import BackendSwitcher from './BackendSwitcher';
import BrandSwitcher from './BrandSwitcher';
import logo from './logo.svg';

const { Header, Content, Footer } = Layout;

const ISSUER = process.env.REACT_APP_ZITADEL_ISSUER as string;

/*
 * Layout
 * General layout component
 * Handles things such as header and footer; defines content window
 */

interface AiLayoutProps {
  children?: React.ReactNode;
}

const AiLayout: React.FC<AiLayoutProps> = ({ children }) => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const navigate = useNavigate()
  const { data, brandAuth } = useSelector((state: RootState) => state.branding);
  const { auth } = useSelector((state: RootState) => state.auth);

  const profile = () => {
    window?.open(ISSUER + '/ui/console/users/me', '_blank')?.focus();
  };

  const logoutUser = () => {
    userManager.signoutRedirect();
  };

  const nav = (item: any) => {
    navigate(item.props.pathname)
  }

  const mainMenuItems = [
    {
      key: '1',
      label: "Content-piece generation",
      pathname: "/"
    },
     {
      key: '2',
      label: "Saved results",
      pathname: "/saved"
    }, 
    ...(brandAuth?.is_global_admin || brandAuth?.is_restricted_admin ? [
      {
       key: '3',
       label: "Administration",
       pathname: "/brand-admin"
     }] : [])
  ];
  const items: MenuProps['items'] = [
    {
      key: '1',
      label: <span onClick={() => profile()}>Profile</span>,
      icon: <ProfileOutlined />,
    },
     {
      key: '2',
      label: <span onClick={() => logoutUser()}>Logout</span>,
      icon: <LogoutOutlined />,
    }, 
  ];

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', paddingRight: 10 }}>
          {!!data?.logoUrl && (
            <img
              src={data?.logoUrl}
              alt={data?.label}
              style={data?.logoStyle}
            />
          )}
          <div
            className="logo"
            style={{
              backgroundImage: `url(${logo})`,
              width: 170,
              height: 32,
              backgroundSize: '100%',
            }}
          />
        </div>
          <Menu 
            theme="dark" 
            mode="horizontal" 
            items={mainMenuItems}
            style={{ flex: 1, minWidth: 0 }}
            //selectedKeys={[mainMenuItems.find(i => i.pathname === location.pathname)?.key || mainMenuItems[0].key]}
            onClick={({item, domEvent}) => {
              nav(item)
            }}
          />
        <div>
          <BrandSwitcher />
          {(brandAuth?.is_brand_admin || brandAuth?.is_global_admin || brandAuth?.is_restricted_admin) && <Tooltip title={brandAuth?.is_brand_admin ? "Brand admin permissions" : "Global admin permissions"}>
            <KeyOutlined style={{color: brandAuth?.is_global_admin ? "red" : "white", marginRight: 10}} />
          </Tooltip> 
          }
          <Dropdown menu={{ items }} overlayStyle={{ paddingTop: 10 }}>
            <span style={{ color: 'white' }}>
              <Space>
                <Avatar icon={<UserOutlined />} />
                {auth?.name || 'Not logged in'}
              </Space>
            </span>
          </Dropdown>
        </div>
      </Header>
      <Content style={{ padding: '0 50px' }}>
        <Layout style={{ padding: '24px 0', background: colorBgContainer }}>
          <Content style={{ padding: '0 24px', minHeight: 280 }}>
            {children}
          </Content>
        </Layout>
      </Content>
      <Footer style={{ textAlign: 'center' }}>
        <Space wrap>
          <Button type="link" href="https://www.ommax-digital.com/en/imprint/">
            Imprint
          </Button>
          <Button type="link">Privacy Policy</Button>
          {process.env.REACT_APP_ENV && process.env.REACT_APP_ENV !== "prod" && <Tooltip title={"Environment: "+process.env.REACT_APP_ENV}><Tag color="cyan">{process.env.REACT_APP_ENV}</Tag></Tooltip>}
        </Space>
        <br />
        {customization.copyright}
        <br />
        <BackendSwitcher />
      </Footer>
    </Layout>
  );
};

export default AiLayout;
