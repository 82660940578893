import { JsonEditor } from 'json-edit-react';

const JsonEditorInput: React.FC<any> = (props) => {
    const {value, onChange} = props;
    return   <JsonEditor
    data={ value }
    setData={ onChange } 
    minWidth={"100%"}
     />;
}

export default JsonEditorInput;