import request from '../utils/request';

export const fetchContent = async (data: any) => {
  const res = await request<any>('/scrapeContent', {
    method: 'post',
    data,
  });

  return res;
};

export const fetchPpm = async (data: any) => {
  const res = await request<any>('/extractPpm', {
    method: 'post',
    data,
  });

  return res;
};
