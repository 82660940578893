import { Alert, AutoComplete, Input, Modal, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { brandAdminList, brandAdminPromote } from '../../data/brandAdmin';
import { IBrandRecord } from '../../data/brands';
  
  interface AdminBrandPromoteModalProps {
    modalVisible: boolean;
    setModalVisible: (isVisible: boolean) => void;
  }
  
  const AdminBrandPromoteModal: React.FC<
  AdminBrandPromoteModalProps
  > = (props) => {
    const { modalVisible, setModalVisible } = props;
    const [promotionCandidate, setPromotionCandidate] = useState<string>();
    const [promotionCandidates, setPromotionCandidates] = useState<IBrandRecord[]>([]);
    const [options, setOptions] = useState<{ value: string }[]>([]);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [isLoadingFailed, setIsLoadingFailed] = useState<boolean>(false);

    const loadData = async () => {
        setLoading(true);
        const list: any = await brandAdminList(true);

        if (list?.list?.length) {
            setPromotionCandidates(list.list as any);
            setOptionsData(undefined, list.list as any);
            setLoading(false);
        } else {
            setIsLoadingFailed(true)
        }
      };
    
      const promote = async () => {
        const id = promotionCandidates?.find((i: any) => i.label === promotionCandidate)?.id;
        setLoading(true)
        console.log(id)
        await brandAdminPromote(id!)
        setLoading(false)
        setModalVisible(false)
      }

      const setOptionsData = (
        search: string | undefined,
        list: IBrandRecord[],
      ) => {
        const mappedList = list
          ?.filter(
            (i) => !search || i.label.toLowerCase().includes(search.toLowerCase()),
          )
          .map((i) => ({ value: i.label, option: i.id }));
        setOptions(mappedList || []);
      };
    
      useEffect(() => {
        if (modalVisible) {
            setPromotionCandidate(undefined)
            loadData();
        }
      }, [modalVisible]);

    return (
      <>
        <Modal
          width={600}
          title="Promote brand configuration"
          open={modalVisible}
          okButtonProps={{ style: { display: promotionCandidate ? undefined : 'none' } }}
          cancelText="Close"
          okText="Promote (OVERRIDES CONFIG!)"
          onCancel={() => setModalVisible(false)}
          onOk={() => promote()}
        >
          <Alert style={{marginBottom: 10}} type="warning" showIcon message="Promote brand configuration" description="This function will promote brand configuration from an upstream environment (e.g. staging for production, develop for staging). Please note that it will create or override the current brand configuration, and this step is irreversible."></Alert>

            {isLoadingFailed ? <>
                <Alert type="error" showIcon message="Could not find promotion candidates" description="Failed finding promotion candidates. No candidates available or no upstream link defined."></Alert>

            </> : <>
            <Spin spinning={isLoading}>
      <AutoComplete
        options={options}
        onSelect={setPromotionCandidate}
        value={promotionCandidate}
        size="large"
        onSearch={(val: any) => setOptionsData(val, promotionCandidates)}
        style={{width: "100%"}}
      >
        <Input size="large" allowClear placeholder='Select upstream brand' />
      </AutoComplete>
    </Spin>
            </>}
        
        </Modal>
      </>
    );
  };
  export default AdminBrandPromoteModal;
  