import request from '../utils/request';


  interface Feedback {
    context?: any;
    rating?: number;
    feedback: string | null;
    page?: any;
    providedEmail:string | null;
    additionalInput?: any;
  }
  

  export const feedback = async (feedback: Feedback) => {
    const res = await request('/feedback', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(feedback),
    });
    return res;
  };