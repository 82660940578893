import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setMockMode } from '../../redux/branding';
import { Select } from 'antd';
import { RootState } from '../../redux';
import { OMMAX_ORG_ID } from '../../redux/auth';
import { setBaseUrl } from '../../utils/request';

interface IBackendUrls {
  [key: string]: string | null;
}

export const BackendUrls: IBackendUrls = {
  default: null,
  mock: 'https://seo-content-frontend.azurewebsites.net/api/mock',
  prod: 'https://seo-content-frontend.azurewebsites.net/api/prod',
};
export const LOCAL_STORAGE_BACKEND = 'seo-backend';

const options = [
  { value: 'default', label: 'Default Backend' },
  { value: 'mock', label: 'M1 Backend' },
  { value: 'prod', label: 'Prod Backend' },
];


const BackendSwitcher: React.FC = () => {
  const [backend, setBackend] = useState<string>(
    localStorage.getItem(LOCAL_STORAGE_BACKEND) || 'default',
  );
  const auth = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();

  const isOmmaxEmployee = auth.auth?.['urn:zitadel:iam:user:resourceowner:id'] === OMMAX_ORG_ID;
  const isProd = process.env.REACT_APP_ENV === "prod";

  useEffect(() => {
    dispatch(setMockMode(["mock","mock2"].includes(backend)))
  }, [backend])

  const changeBackend = (newBackend: string) => {
    localStorage.setItem(LOCAL_STORAGE_BACKEND, newBackend);
    setBackend(newBackend)
    setBaseUrl(BackendUrls[newBackend] || null)
    window.location.reload();
  }
  
  useEffect(() => {
    dispatch(setMockMode(['mock', 'mock2'].includes(backend)));
  }, [backend]);

  // hide when not in prod or not OMMAX employee
  if (!isOmmaxEmployee || !isProd) return null;

  return     <Select
  value={backend}
  onChange={val => changeBackend(val)}
  style={{ width: 150 }}
  bordered={false}
  options={options}
/>
};

export default BackendSwitcher;
