interface CustomizationInterface {
  productName: string;
  copyright: string;
}

/*
 * Customization definition
 * Can be used to customize the product... for future use
 */

const customization: CustomizationInterface = {
  productName: 'OMMAX AI SEO',
  copyright: '© '+new Date().getFullYear()+' OMMAX - Building Digital Leaders',
};

export { customization };
