import { FrownOutlined, MehOutlined, MessageOutlined, SmileOutlined } from '@ant-design/icons';
import { Alert, FloatButton, Input, Modal, Rate, Space, Tooltip, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { feedback } from '../../data/feedback';
import { RootState } from '../../redux';

const customIcons: Record<number, React.ReactNode> = {
  1: <FrownOutlined />,
  2: <FrownOutlined />,
  3: <MehOutlined />,
  4: <SmileOutlined />,
  5: <SmileOutlined />,
};

const { TextArea } = Input;

interface FeedbackParams {
  step: number | string;
  additionalInput?: any;
}

const Feedback: React.FC<FeedbackParams> = ({step, additionalInput}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [text, setText] = useState("");
  const [rating, setRating] = useState<number>();
  const [messageApi, contextHolder] = message.useMessage();

  const { params } = useSelector(
    (state: RootState) => state.generation,
  );
  const userEmail = useSelector((state: RootState) => 
    state.auth.auth?.email ? state.auth.auth.email : 'undefined'
  );

  useEffect(() => {
    if (isModalOpen) setText("")
  }, [isModalOpen])

  const submitFeedback = async () => {
      setIsSubmitting(true);
      await feedback({context:params, feedback:text, page:step, rating, providedEmail:userEmail, additionalInput})
      setIsSubmitting(false);
      setIsModalOpen(false);
      messageApi.success("Feedback sent successfully")
  }

  return (
    <>
      <FloatButton onClick={() => setIsModalOpen(true)} icon={<Tooltip title="Send Feedback"><MessageOutlined /></Tooltip>} />

      <Modal
        title="Provide Feedback"
        open={isModalOpen}
        okText="Submit"
        confirmLoading={isSubmitting}
        onCancel={() => setIsModalOpen(false)}
        onOk={() => submitFeedback()}
      >
        <Space direction='vertical'>
          
            <Alert message="The development team will receive all information from your session and current page, including the data you input and the responses you received, as well as your email address." type="info" showIcon />

        <p>
          How did you like the output? <br />
          <Rate value={rating} character={({ index = 0 }) => customIcons[index + 1]} onChange={setRating}/>
        </p>

        <TextArea
          placeholder="Please provide us with your feedback!"
          autoSize={{ minRows: 5 }}
          value={text}
          onChange={t => setText(t.target.value)}
        />
        </Space>
      </Modal>
      {contextHolder}
    </>
  );
};

export default Feedback;
