import {
  DiffOutlined
} from '@ant-design/icons';
import {
  Button,
  Dropdown,
  MenuProps
} from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux';

interface AiInterfaceResultRegenerateProps {
  reExecutePrompt?: (id: number) => void;
  loading: boolean;
}

const AiInterfaceResultRegenerate: React.FC<AiInterfaceResultRegenerateProps> = (props) => {
  const { reExecutePrompt, loading } = props;
  
  const settings = useSelector((state: RootState) => state.branding.settings);

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    if (e.key === "all") {
      // TODO
    }
    reExecutePrompt?.(Number(e.key))
  };

  const items: MenuProps['items'] = [
    ... (settings?.regenerate?.map(i => {
      return {
        key: String(i.id),
        label: i.name
      }
    }) || []),
 /* {
    key: 'div1',
    type: "divider"
  },
    {
      key: 'all',
      label: 'Entire document',
      icon: <FileOutlined />
    },*/
  ];
  
  if (!settings?.regenerate) return null;
  
  return (
    <>
        <Dropdown
                  menu={{
                    items,
                    onClick: handleMenuClick,
                  }}
                  disabled={loading}
                >
                <Button
                  htmlType="submit"
                  icon={<DiffOutlined />}
                  disabled={loading}
                >
                  Regenerate
                </Button>
        </Dropdown>
    </>
  );
};
export default AiInterfaceResultRegenerate;
