import Mark from 'mark.js';
import { IBrandSettings } from '../data/brands';
import { countSentences, countTotalSyllables, countWords } from './text';
//import { natural } from 'natural';

/*
 * Quality checks are defined by OMMAX and run after text generation
 * activated using the setting qualityCheck
 */

export interface QualityCheckItem {
  category: QualityCategory;
  passed: boolean;
  title: string;
  currentValue?: string | number;
  limitValue?: string | number;
  goal: string;
  passedMessage: string;
  failedMessage: string;
  canHighlightIssue?: boolean;
  highlight?: (mark: Mark, opts: Mark.MarkOptions) => void;
  // details depend on quality check item type
  details?: any;
}

export enum InternalLinkIssueType {
  SUBMITTED_LINK_NOT_INCLUDED,
  SUBMITTED_LINK_WRONG_TITLE,
  SUBMITTED_LINK_WRONG_LINK,
  ADDITIONAL_LINK
}

export interface InternalLinkIssue {
  key: number;
  type: InternalLinkIssueType, 
  nameShouldBe?: string | undefined | null, 
  linkShouldBe?: string | undefined | null, 
  nameAsIs?: string | undefined | null, 
  linkAsIs?: string | undefined | null
}

enum QualityCategory {
  READABILITY,
  SEO,
}

export interface InternalLink {
  name: string;
  link: string;
}

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

class QualityCheck {
  text: string;
  organization: string | null;
  ltext: string;
  html: HTMLDivElement;
  mainKeyword: string;
  keywords: string[] = [];
  faqs: string[] = [];
  internalLinks: InternalLink[] = [];
  items: QualityCheckItem[] = [];
  settings: IBrandSettings | undefined = undefined;
  otherParams: any = {};
  passed = 0;
  failed = 0;
  total = 0;

  constructor(
    text: string,
    html: HTMLDivElement,
    settings: IBrandSettings | undefined,
    mainKeyword: string,
    keywords: string[],
    faqs: string[],
    internalLinks: any[],
    organization: string | null,
    otherParams: any
  ) {
    this.settings = settings;
    this.text = text;
    this.html = html;
    this.organization = organization;
    this.mainKeyword = mainKeyword || '';
    this.keywords = keywords || [];
    this.faqs = faqs || [];
    this.internalLinks = internalLinks || [];
    this.ltext = text.toLocaleLowerCase();
    this.otherParams = otherParams;
    this.run();
    this.analyze();
  }

  // runs the quality check
  run() {
    if (!this.settings?.qualityCheck) {
      this.items = [];
    }  else {
      // @ts-ignore
      const filteredForExisting = this.settings?.qualityCheck?.filter(checkItem => !!this[checkItem.module]);
      // @ts-ignore
      this.items = filteredForExisting?.map(checkItem => this[checkItem.module](checkItem));
    }
  }

  analyze() {
    this.passed = this.items.filter((i) => i.passed).length;
    this.failed = this.items.length - this.passed;
    this.total = this.items.length;
  }

  private countLists() {
    const document = this.html;
    const unorderedLists = document.querySelectorAll('ul').length;
    const orderedLists = document.querySelectorAll('ol').length;

    return {
      passed: true, // This check always passes as it's informational
      category: QualityCategory.READABILITY,
      title: 'List Count',
      goal: 'Count the number of ordered and unordered lists in the text.',
      currentValue: `Unordered Lists: ${unorderedLists}, Ordered Lists: ${orderedLists}`,
      passedMessage: `Number of unordered lists: ${unorderedLists}\nNumber of ordered lists: ${orderedLists}`,
      failedMessage: '', // This will never be used as the check always passes
    };
  }

  private checkH1Length() {
    const document = this.html;
    const h1 = document.querySelector('h1');
    
    if (!h1 || !h1.textContent) {
      return {
        passed: false,
        category: QualityCategory.READABILITY,
        title: 'H1 Headline Length',
        goal: 'H1 should have less than 60 characters and less than 5 words.',
        currentValue: 'No H1 found',
        passedMessage: '',
        failedMessage: 'No H1 headline found in the document.',
        canHighlightIssue: false,
      };
    }
  
    const text = h1.textContent.trim();
    const charCount = text.length;
    const wordCount = text.split(/\s+/).length;
  
    const charPassed = charCount < 60;
    const wordPassed = wordCount < 5;
  
    let failedMessage = '';
    if (!charPassed && !wordPassed) {
      failedMessage = `H1 fails both criteria. It has ${charCount} characters (limit: 60) and ${wordCount} words (limit: 5).`;
    } else if (!charPassed) {
      failedMessage = `H1 has ${charCount} characters, which exceeds the limit of 60.`;
    } else if (!wordPassed) {
      failedMessage = `H1 has ${wordCount} words, which exceeds the limit of 5.`;
    }
  
    return {
      passed: charPassed && wordPassed,
      category: QualityCategory.READABILITY,
      title: 'H1 Headline Length',
      goal: 'H1 should have less than 60 characters and less than 5 words.',
      currentValue: `Characters: ${charCount}, Words: ${wordCount}`,
      passedMessage: 'H1 headline meets both character and word count criteria.',
      failedMessage: failedMessage,
      canHighlightIssue: false,
    };
  }

  private checkMainKeywordInH1() {
    const document = this.html;
    const h1 = document.querySelector('h1');
    const mainKeyword = this.mainKeyword;

    if (!h1 || !h1.textContent) {
      return {
        passed: false,
        category: QualityCategory.SEO,
        title: 'Main Keyword in H1',
        goal: 'The main keyword should be present in the H1 headline.',
        currentValue: 'No H1 found',
        passedMessage: '',
        failedMessage: 'No H1 headline found in the document.',
        canHighlightIssue: false,
      };
    }
  
    const h1Text = h1.textContent.trim().toLowerCase();
    const keywordPresent = h1Text.includes(mainKeyword.toLowerCase());
  
    return {
      passed: keywordPresent,
      category: QualityCategory.SEO,
      title: 'Main Keyword in H1',
      goal: 'The main keyword should be present in the H1 headline.',
      currentValue: h1Text,
      passedMessage: `The main keyword "${mainKeyword}" is present in the H1 headline.`,
      failedMessage: `The main keyword "${mainKeyword}" is not present in the H1 headline.`,
      canHighlightIssue: true,
    };
  }

  private readabilityElements(props: {element: string, labelSingular: string, labelPlural: string}) {
    const currentValue =
      this._countOccurrences('<'+props.element+'>');

    return {
      passed: currentValue > 0,
      category: QualityCategory.READABILITY,
      title: capitalizeFirstLetter(props.labelSingular)+' elements',
      goal: 'The text should contain at least one '+props.labelSingular+'.',
      currentValue,
      passedMessage: `The text contains ${currentValue} ${currentValue === 1 ? props.labelSingular : props.labelPlural}.`,
      failedMessage: 'Please check if at least one '+props.labelSingular+' can be implemented',
    };
  }

  private wordsBetweenHeadlines(props: {headlineTag: string | string[], minWords: number, maxWords: number, stopTag?: string}) {
    const {headlineTag, minWords = 50, maxWords = 250, stopTag} = props;
    const document = this.html; // Using the existing HTML structure
    const unifiedHeadlines = Array.isArray(headlineTag) ? headlineTag.join(',') : headlineTag;
    const headlineTags = Array.from(document.querySelectorAll(unifiedHeadlines));
    const countList: number[] = [];

    // Handle case when there are no headlines of the given type
    if (!headlineTags || headlineTags.length === 0) {
      return [];
    }

    // Count words between each pair of headline tags
    for (let i = 0; i < headlineTags.length; i++) {
      let start = headlineTags[i];
      let end = i !== headlineTags.length - 1 ? headlineTags[i + 1] : null;
      let wordCount = 0;

      // Traverse sibling nodes between the current headline and the next one
      let sibling = start.nextElementSibling;
      while (sibling && sibling !== end) {
        // Check if sibling matches the stop tag
        if (stopTag && sibling.matches(stopTag)) {
          break; // Stop counting if the stopTag is found
        }
        
        if (sibling.textContent) {
          wordCount += sibling.textContent.split(/\s+/).length;
        }
        sibling = sibling.nextElementSibling;
      }

      countList.push(wordCount);
    }

    // Now generate the result report based on the count
    let report = "";
    let p_num = 0;
    countList.forEach((count) => {
      p_num += 1;
      if (count < minWords || count > maxWords) {
        report += `- Paragraph ${p_num} has ${count} words. `;
        report += count < minWords ? "This is below the minimum of " + minWords + " words.\n" : "This exceeds the maximum of " + maxWords + " words.\n";
      } else {
        report += `- Paragraph ${p_num} length is between ${minWords} and ${maxWords} words. \n`;
      }
    });

    return {
      passed: countList.every(count => count >= minWords && count <= maxWords),
      category: QualityCategory.READABILITY,
      title: `Word count between ${unifiedHeadlines.toUpperCase()} tags`,
      goal: `Each section between ${unifiedHeadlines} tags should have a word count between ${minWords} and ${maxWords}.`,
      currentValue: countList.join(', '), // Displays all word counts for the sections
      passedMessage: `All paragraphs between ${unifiedHeadlines} tags meet the word count criteria.`,
      failedMessage: report,
      canHighlightIssue: false,
    };
}

  /*private wordsBetweenHeadlines() {
    const document = this.html;
    const minWords = 50;
    const maxWords = 250;
    const headlineTag = 'h2';
    const h1 = document.querySelector('h1');
    const h2s = document.querySelectorAll('h2');
    const h4 = document.querySelector('h4');
    const countList: number[] = [];
  
    // First paragraph: between h1 and first h2
    //if (h1 && h2s.length > 0) {
      //let wordCount = 0;
      //let sibling = h1.nextElementSibling;
      //while (sibling && sibling !== h2s[0]) {
        //if (sibling.textContent) {
         // wordCount += sibling.textContent.split(/\s+/).length;
        //}
        //sibling = sibling.nextElementSibling;
      //}
      //countList.push(wordCount);
    //}
  
    // Paragraphs between h2s
    for (let i = 0; i < h2s.length - 1; i++) {
      let wordCount = 0;
      let sibling = h2s[i].nextElementSibling;
      while (sibling && sibling !== h2s[i+1]) {
        if (sibling.textContent) {
          wordCount += sibling.textContent.split(/\s+/).length;
        }
        sibling = sibling.nextElementSibling;
      }
      countList.push(wordCount);
    }
  
    // Last paragraph: between last h2 and first h4
    if (h2s.length > 0 && h4) {
      let wordCount = 0;
      let sibling = h2s[h2s.length - 1].nextElementSibling;
      while (sibling && sibling !== h4) {
        if (sibling.textContent) {
          wordCount += sibling.textContent.split(/\s+/).length;
        }
        sibling = sibling.nextElementSibling;
      }
      countList.push(wordCount);
    }

    // Now generate the result report based on the count
    let report = "";
    let p_num = 0;
    countList.forEach((count) => {
      p_num += 1;
      if (count < minWords || count > maxWords) {
        report += `- Paragraph ${p_num} has ${count} words. `;
        report += count < minWords ? "This is below the minimum of 50 words.\n" : "This exceeds the maximum of 250 words.\n";
      }
    });

    return {
      passed: countList.every(count => count >= minWords && count <= maxWords),
      category: QualityCategory.READABILITY,
      title: `Word count between ${headlineTag.toUpperCase()} tags`,
      goal: `Each section between ${headlineTag} tags should have a word count between ${minWords} and ${maxWords}.`,
      currentValue: countList.join(', '), // Displays all word counts for the sections
      passedMessage: `All paragraphs between ${headlineTag} tags meet the word count criteria.`,
      failedMessage: report,
      canHighlightIssue: false,
    };
  }*/

  // definition for single quality check dimensions
  private readabilityFormal() {
    const keywords = ['sie', 'ihrer', 'ihren'];
    const regex = new RegExp(keywords.join('|'), 'i');
    const passed = !regex.test(this.text);

    return {
      passed,
      category: QualityCategory.READABILITY,
      title: 'Informal addressee',
      goal: 'No counts of “Sie”, “Ihrer”, “Ihren” in the text.',
      passedMessage: 'Informal addressee implemented',
      failedMessage: 'Please check again if informal addressee is followed',
      canHighlightIssue: false,
      highlight: (mark: Mark, opts: Mark.MarkOptions) => {
        mark.mark('sie', opts);
        mark.mark('ihrer', opts);
        mark.mark('ihren', opts);
      },
    };
  }

  private readabilityBulletPoints() {
    const currentValue =
      this._countOccurrences('<ul>') + this._countOccurrences('<ol>');

    return {
      passed: currentValue > 0,
      category: QualityCategory.READABILITY,
      title: 'List elements',
      goal: 'The text should contain at least one list.',
      currentValue,
      passedMessage: `The text contains ${currentValue} lists`,
      failedMessage: 'Please check if bullet points can be implemented',
    };
  }


  private keywordIntegration() {
    const isNotDefined = this.mainKeyword.length === 0;
    const missingKeywords: string[] = [];
    const keywords = [this.mainKeyword, ...this.keywords];
    const total = keywords.length;
    let currentValue = 0;

    for (const keyword of keywords) {
      const count = this._countOccurrences(keyword, true);

      if (!count) {
        missingKeywords.push(keyword);
        continue;
      }

      currentValue += 1;
    }

    return {
      passed: currentValue === total,
      category: QualityCategory.SEO,
      title: 'Keyword integration',
      goal: 'The text should contain the primary and all selected and manually added secondary keywords.',
      currentValue,
      passedMessage: 'Primary and secondary keywords are integrated',
      failedMessage: isNotDefined
        ? 'Primary keyword is not defined'
        : `Please add the following keywords to the text: ${missingKeywords.join(', ')}`,
    };
  }

  private introTextLengthWithoutH1(props: {maxLength?: number}) {
    const {maxLength = 350} = props;
    const length = this._countCharsBefore('h2');
    const tooLong = length > maxLength;

    return {
      passed: !tooLong,
      category: QualityCategory.READABILITY,
      title: 'Intro text length',
      goal: 'The intro text (paragraph before the H2 headline) should have a length of max. '+maxLength+' characters',
      passedMessage: 'Intro text length is '+length+' characters',
      failedMessage: tooLong
        ? 'Please check the intro text and shorten from '+length+' to '+maxLength+' characters maximum'
        : '',
      canHighlightIssue: false,
    };
  }


  private introTextLengthWithH1(props: {maxLength?: number}) {
    const {maxLength = 350} = props;
    const length = this._countCharsBetween('h1', 'h2');
    const tooLong = length > maxLength;

    return {
      passed: !tooLong,
      category: QualityCategory.READABILITY,
      title: 'Intro text length',
      goal: 'The intro text (paragraph below the H1 headline) should have a length of max. '+maxLength+' characters',
      passedMessage: 'Intro text length is '+length+' characters',
      failedMessage: tooLong
      ? 'Please check the intro text and shorten from '+length+' to '+maxLength+' characters maximum'
        : '',
      canHighlightIssue: false,
    };
  }

  private faqTextLength() {
    const length = this._countCharsInside('dl');
    const tooLong = length > 750;
    const tooShort = length < 100;

    return {
      passed: !tooLong && !tooShort,
      category: QualityCategory.READABILITY,
      title: 'FAQ Length',
      goal: 'The text for each FAQ should have a length between 100 and 750 characters',
      passedMessage:
        'Length of each FAQ text is between 100 and 750 characters',
      failedMessage:
        (tooLong
          ? 'Please check the FAQ text and shorten it to a maximum of 750 characters per FAQ'
          : '') +
        (tooShort
          ? 'Please check the FAQ text and expand it to at least 100 characters per FAQ'
          : ''),
      canHighlightIssue: false,
    };
  }

  private seoTextLength() {
    const length = this._countCharsBetweenIdenticalTags('h2');
    const tooLong = length > 1500;
    const tooShort = length < 600;

    return {
      passed: !tooLong && !tooShort,
      category: QualityCategory.READABILITY,
      title: 'SEO Text Length',
      goal: 'The SEO text (paragraphs below the H2 headline) should have a length between 600 and 1500 characters',
      passedMessage: 'SEO text length is '+length+' characters',
      failedMessage:
        (tooLong
          ? 'Please check the SEO text and shorten it from '+length+' to a maximum of 1500 characters'
          : '') +
        (tooShort
          ? 'Please check the SEO text and expand it from '+length+' to at least 600 characters'
          : ''),
      canHighlightIssue: false,
    };
  }

  private _removeHtmlTags(input: string) {
    return input
      .replace(/<\/?[^>]+(>|$)/g, '')
      .replace(/&nbsp;/g, '')
      .replace(/\n/g, '');
  }
  private _calculateTotalLengthOfHtmlTags(htmlString: string) {
    // Match all HTML tags
    const regex = /<\/?[\w\s="/.':;#-/]+>/gi;
    const tags = htmlString.match(regex) || [];

    // Calculate the total length of all tags
    let totalLength = 0;
    for (const tag of tags) {
      totalLength += tag.length;
    }

    return totalLength;
  }

  private seoKeywordDensity(props: {max: number}) {
    const {max = 0.03} = props;
    const currentValue = this._countMainKeyword();

    const cleanedText = this.ltext.replace(/<.*?>/g, '');
    let kd = currentValue / countWords(cleanedText);

    return {
      passed: kd < max,
      category: QualityCategory.SEO,
      title: 'Keyword density is ' + (kd * 100).toFixed(2) + '%',
      goal: 'The keyword density of the primary keyword should be below '+(max*100)+'% to avoid keyword stuffing.',
      currentValue,
      passedMessage: 'No keyword stuffing',
      failedMessage:
        'Primary keyword was used too often. Please check to replace it with variations, synonyms or long-tail combinations.',
    };
  }

  private seoKeywordDensityAbove1() {
    const currentValue = this._countMainKeyword();

    const cleanedText = this.ltext.replace(/<.*?>/g, '');
    //const stemmedText = natural.PorterStemmer.tokenizeAndStem(cleanedText);
    //const stemmedKeyword = natural.PorterStemmer.stem(this.mainKeyword);

    let kd = currentValue / countWords(cleanedText);
    //let kd = stemmedText.filter(word => word === stemmedKeyword).length / stemmedText.length;

    return {
      passed: kd > 0.01,
      category: QualityCategory.SEO,
      title: 'Keyword density is ' + (kd * 100).toFixed(2) + '%',
      goal: 'Density of the primary keyword should be above 1%.',
      currentValue: kd,
      passedMessage: 'Optimal keyword stuffing',
      failedMessage:
        'Primary keyword was used not often enough.',
    };
  }

  private linktoCategoryUrl(categoryUrl: string) {
    const document = this.html;
    const links = document.querySelectorAll('a');
    let found = false;

    links.forEach((link) => {
      const href = link.getAttribute('href');
      if (href === categoryUrl) {
        found = true;
      }
    });

    return {
      passed: found,
      category: QualityCategory.SEO,
      title: 'Contains Category URL Link',
      goal: 'The text should contain a link to the category URL.',
      currentValue: found ? 'Link found' : 'Link not found',
      passedMessage: 'The text contains a link to the category URL.',
      failedMessage: 'The text does not contain a link to the category URL.',
    };
  }

  private textWordCount() {
    const minWordCount = 500;
    const text = this.text;
    const wordCount = this._wordCount(text);

    return {
      passed: wordCount > minWordCount,
      category: QualityCategory.READABILITY,
      title: 'Word Count: '+ wordCount,
      goal: `The text should contain more than ${minWordCount} words.`,
      currentValue: wordCount,
      passedMessage: `The text contains more than ${minWordCount} words (word count: ${wordCount}).`,
      failedMessage: `The text contains only ${wordCount} words, which is less than the required ${minWordCount} words.`,
    };
  }

  private singleH1() {
    const document = this.html;
    const h1Tags = document.querySelectorAll('h1');
    const h1Count = h1Tags.length;

    return {
      passed: h1Count === 1,
      category: QualityCategory.READABILITY,
      title: 'Only one H1?',
      goal: 'The text should contain exactly one H1 tag.',
      currentValue: h1Count,
      passedMessage: 'The text contains exactly one H1 tag.',
      failedMessage: h1Count === 0
        ? 'The text does not contain any H1 tags.'
        : `The text contains ${h1Count} H1 tags, which is more than one.`,
    };
  }

  private h2Count() {
    const minH2Count = 4;
    const document = this.html;
    const h2Tags = document.querySelectorAll('h2');
    const h2Count = h2Tags.length;

    return {
      passed: h2Count >= minH2Count,
      category: QualityCategory.READABILITY,
      title: 'H2 Count',
      goal: `The text should contain at least ${minH2Count} H2 tags.`,
      currentValue: `H2: ${h2Count}`,
      passedMessage: `The text contains at least ${minH2Count} H2 tags (current number of H2 tags: ${h2Count}).`,
      failedMessage: `The text contains ${h2Count} H2 tags, which does not meet the minimum requirement of ${minH2Count}.`,
    };
  }

  private h3Count() {
    const minH3Count = 5;
    const document = this.html;
    const h3Tags = document.querySelectorAll('h3');
    const h3Count = h3Tags.length;

    return {
      passed: h3Count >= minH3Count,
      category: QualityCategory.READABILITY,
      title: 'H3 Count',
      goal: `The text should contain at least ${minH3Count} H3 tags.`,
      currentValue: `H3: ${h3Count}`,
      passedMessage: `The text contains at least ${minH3Count} H3 tags (current number of H2 tags: ${h3Count}).`,
      failedMessage: `The text contains ${h3Count} H3 tags, which does not meet the minimum requirement of ${minH3Count}.`,
    };
  }

  private checkHtmlValidity() {
    const document = this.html;
    //const isValidHtml = document.querySelector('html') !== null && document.querySelector('body') !== null;

    // Check for unclosed tags
    const htmlString = document.innerHTML;
    const unclosedTags = this._findUnclosedTags(htmlString);

    return {
      passed: unclosedTags.length === 0, //&& isValidHtml,
      category: QualityCategory.READABILITY,
      title: 'HTML Validity',
      goal: 'The HTML should be valid with no unclosed tags.', //and should contain <html> and <body> tags.
      //currentValue: isValidHtml ? 'Valid HTML' : 'Invalid HTML',
      passedMessage: 'The HTML is valid.',
      failedMessage: unclosedTags.length > 0
        ? `The HTML has unclosed tags: ${unclosedTags.join(', ')}`
        : 'The HTML does not have the correct format.',
    };
  }

  private _findUnclosedTags(htmlString: string): string[] {
    const tagPattern = /<\/?([a-zA-Z]+)[^>]*>/g;
    const stack: string[] = [];
    let match: RegExpExecArray | null;

    while ((match = tagPattern.exec(htmlString)) !== null) {
      const tag = match[1];
      if (match[0][1] === '/') {
        // Closing tag
        if (stack.length === 0 || stack.pop() !== tag) {
          return [tag]; // Unmatched closing tag
        }
      } else {
        // Opening tag
        stack.push(tag);
      }
    }

    return stack; // Unclosed tags
  }

  private pageTitleLength() {
    const minLength = 20;
    const maxLength = 55;
    const text = this.text.replace(/<.*?>|[*]{2}|[#]{3}/g, '');
    const titleStart = text.indexOf("Page Title:") + "Page Title:".length;
    const titleEnd = text.indexOf("Meta Description:");
    const title = text.substring(titleStart, titleEnd).trim();
    const titleLength = title.length;

    return {
      passed: titleLength >= minLength && titleLength <= maxLength,
      category: QualityCategory.SEO,
      title: 'Optimal Page Title Length',
      goal: `The page title should be between ${minLength} and ${maxLength} characters.`,
      currentValue: titleLength,
      passedMessage: `The page title is between ${minLength} and ${maxLength} characters.`,
      failedMessage: `The page title is ${titleLength} characters, which does not meet the requirement.`,
    };
  }

  private metaDescriptionLength() {
    const minLength = 120;
    const maxLength = 155;
    const text = this.text.replace(/<.*?>|[*]{2}|[#]{3}/g, '');
    const descriptionStart = text.indexOf("Meta Description:") + "Meta Description:".length;
    const description = text.substring(descriptionStart).trim();
    const descriptionLength = description.length;

    return {
      passed: descriptionLength >= minLength && descriptionLength <= maxLength,
      category: QualityCategory.SEO,
      title: 'Optimal Meta Description Length',
      goal: `The meta description should be between ${minLength} and ${maxLength} characters.`,
      currentValue: descriptionLength,
      passedMessage: `The meta description is between ${minLength} and ${maxLength} characters.`,
      failedMessage: `The meta description is ${descriptionLength} characters, which does not meet the requirement.`,
    };
  }
  
  private readabilityFlesh(props: {min: number, max: number}) {
    const {min = 50, max = 60} = props;
    const currentValue = this._fleschReadingEaseScoreNew();

    return {
      passed: currentValue >= min && currentValue <= max,
      category: QualityCategory.READABILITY,
      title: 'Flesch reading ease score is ' + currentValue,
      goal: 'A Flesh reading ease score of '+min+'-'+max+' determines a medium weight.',
      currentValue,
      passedMessage: 'Flesch reading ease score goal achieved',
      failedMessage:
        'Please check again the text and shorten sentences and simplify words',
    };
  }

  private readabilityHeadlines(props: {hlevel?: string, maxLength?: number}) {
    const {hlevel = 'h1', maxLength = 60} = props;
    const document = this.html;

    const headlines = document.querySelectorAll(hlevel);
    let error = '';
    let ranges: any = [];

    if (headlines.length === 0) {
      error = `No ${hlevel} headline found`;
    }

    headlines.forEach((h) => {
      let hText = h.textContent;
      if (!hText) return;
      if (maxLength && hText.length > maxLength) {
        error = `Please check if the ${hlevel} should be shortend (< ${maxLength} characters).`;
      }
      if (!hText.toLowerCase().includes(this.mainKeyword.toLowerCase())) {
        error = `Please integrate the primary keyword in the ${hlevel}`;
      }
      if (error)
        ranges.push(this._createRangeFromHtml(document, h as HTMLElement));
    });

    return {
      passed: error === '',
      category: QualityCategory.READABILITY,
      title: `${hlevel} optimization`,
      goal: `The ${hlevel} headline should contain the primary keyword${maxLength ? ` and less than ${maxLength} characters` : ''}.`,
      passedMessage: `${hlevel} headline is keyword optimized${maxLength ? ` and has the best practice length`: ''}`,
      failedMessage: error,
      canHighlightIssue: false,
      highlight: (mark: Mark, opts: Mark.MarkOptions) => {
        if (ranges) mark.markRanges(ranges, opts);
      },
    };
  }

  private faqIntegration() {
    const total = this.faqs.length;
    const currentValue = this._countOccurrences('<dt>');

    return {
      passed: currentValue === total,
      category: QualityCategory.READABILITY,
      title: 'FAQ integration',
      goal: 'The text should contain all of the selected and manually added FAQ questions.',
      passedMessage: 'All provided FAQ questions are implemented',
      failedMessage: `The text contains ${currentValue} of ${total} provided FAQs. Please review the text and consider adding the missing FAQs.`,
      canHighlightIssue: false,
    };
  }

  private checkInternalLinks() {
    const document = this.html;
    let present = 0;
    let issues: InternalLinkIssue[] = [];
    const foundLinks: any[] = [];

    const links = document.querySelectorAll('a');

    console.log(this.internalLinks)
    // for each link in document
    links.forEach((link) => {
      const linkAsIs = link?.getAttribute('href');
      const nameAsIs = link?.innerText;

      // match by href or label against internal links deifned
      const foundLink = this.internalLinks.find((l) => (linkAsIs && l?.link === linkAsIs) || (nameAsIs && l?.name === nameAsIs));
      foundLinks.push(foundLink);

      const linkShouldBe = foundLink?.link;
      const nameShouldBe = foundLink?.name
      const issueData = {
        linkAsIs,
        nameAsIs,
        linkShouldBe,
        nameShouldBe
      }

      // no match found
      if (!foundLink) {
        issues.push({
          key: issues.length,
          type: InternalLinkIssueType.ADDITIONAL_LINK,
          ...issueData
        })
        return;
      }

      // match found
      present++;

      // link doesnt match
      if (nameAsIs === nameShouldBe && linkAsIs !== linkShouldBe) {
        issues.push({
          key: issues.length,
          type: InternalLinkIssueType.SUBMITTED_LINK_WRONG_LINK,
          ...issueData
        })
      } else 
      // name doesnt match
      if (nameAsIs !== nameShouldBe && linkAsIs === linkShouldBe) {
        issues.push({
          key: issues.length,
          type: InternalLinkIssueType.SUBMITTED_LINK_WRONG_TITLE,
          ...issueData
        })
      }
    });

    // check for all internal links if they are part of doc
    this.internalLinks.forEach(link => {
      // should be part of links found
      const foundLink = foundLinks.find((l) => (l?.link === link?.link) || (l?.name === link?.name));

      if (!foundLink) {
        issues.push({
          key: issues.length,
          type: InternalLinkIssueType.SUBMITTED_LINK_NOT_INCLUDED,
          linkShouldBe: link?.link,
          nameShouldBe: link?.name
        })
      }
    })

    return {
      passed:
        issues.length === 0,
      category: QualityCategory.READABILITY,
      title: 'Internal links',
      goal: 'The text should contain all internal links that were provided as input.',
      passedMessage: 'All internal links are included',
      failedMessage: "Found "+issues.length+" issues with internal links",
      details: issues,
      canHighlightIssue: false,
    };
  }

  // helpers for checking content
  private _createRange(start: number, length: number) {
    return { start, length };
  }

  private _createRangeFromHtml(doc: HTMLDivElement, element: HTMLElement) {
    return this._createRange(
      doc.innerHTML.indexOf(element.outerHTML),
      element.innerHTML.length,
    );
  }

  private _fleschReadingEaseScoreNew() {
    let pageText = this._getPlainText();
    let language: string = this.otherParams.language || "en";
    if (language === "de_informal") language = "de";

    const sentence = countSentences(pageText);
    const word = countWords(pageText);
    const syllable = countTotalSyllables(pageText);

    const calculator = {
      "en": Math.round(206.835 - (1.015 * (word/sentence)) - (84.6 * (syllable/word))), // English
      "de": Math.round(180 - (word/sentence) - (58.5 * (syllable/word))), // German
      "es": Math.round(207 - (1.02 * (word/sentence)) - (62 * (syllable/word))), // Spanish
      "fr": Math.round(207 - (1.015 * (word/sentence)) - (73.6 * (syllable/word))), // French
      "it": Math.round(217 - (1.3 * (word/sentence)) - (67.8 * (syllable/word))), // Italian
      "nl": Math.round(206.835 - (0.93 * (word/sentence)) - (77 * (syllable/word))), // Dutch
      "pt": Math.round(207 - (0.93 * (word/sentence)) - (70 * (syllable/word))), // Portuguese
      "ru": Math.round(206.835 - (1.3 * (word/sentence)) - (60 * (syllable/word))), // Russian
      "zh": Math.round(206.835 - (1.5 * (word/sentence)) - (90 * (syllable/word))), // Simplified Chinese (approximation)
      "ar": Math.round(206.835 - (1.2 * (word/sentence)) - (70 * (syllable/word)))  // Arabic (approximation)
  };

    // @ts-ignore
    return calculator[language || "en"] || calculator["en"];
  }

  private _indexOfEnd = function (base: string, string: string) {
    var io = base.indexOf(string);
    return io === -1 ? -1 : io + string.length;
  };

  private _countOccurrences(value: string, pageTextOnly = false) {
    if (!value) return 0;

    const txt = (
      pageTextOnly ? this._getPageText() : this.ltext
    ).toLocaleLowerCase();
    const val = value.toLocaleLowerCase().trim();
    return txt.split(val).length - 1;
  }

  private _getPageText() {
    const document = this.html;
    let pageText = '';
    const paragraphs = document.querySelectorAll('p');

    paragraphs.forEach((p) => {
      pageText += p.textContent + '\n';
    });

    return pageText;
  }

  private _getPlainText() {
    const document = this.text;

    // Remove all HTML tags
    let text = document.replace(/<\/?[^>]+(>|$)/g, "");
    
    // Replace multiple whitespaces and newlines with a single space
    text = text.replace(/\s+/g, " ").trim();
    
    return text;
  }

  private _countMainKeyword() {
    if (!this.mainKeyword) return 0;
    return this._countOccurrences(this.mainKeyword);
  }

  private _wordCount(text: string | undefined | null) {
    if (!text) return 0;
    return text.split(/\s+/).length || 0;
  }
  private _charCount(text: string | undefined | null) {
    if (!text) return 0;
    return this._removeHtmlTags(text).length || 0;
  }

  private _countCharsInside(el: string) {
    const tag = document.querySelector(el);

    if (!tag) return 0;

    const text = tag.textContent;
    const count = this._charCount(text);

    return count;
  }

  private _countCharsBefore(end: string) {
    const document = this.html;
    const endTag = document.querySelector(end);

    if (!endTag) return 0;

    const startPos = 0
    const endPos = document.innerHTML.indexOf(endTag.outerHTML);
    const text = document.innerHTML.substring(startPos, endPos);
    const charCount = this._charCount(text);

    return charCount;
  }

  /* 
   * Counts the characters between start and end tag, but end tag must be after start tag 
   * More stable approach e.g. allowing for using same tag
   */
  private _countCharsBetweenIdenticalTags(tagName: string) {
    const doc = this.html;
    
    // Get all elements matching the specified tag name
    const tags = doc.querySelectorAll(tagName);
    
    if (tags.length < 2) {
      console.log("Less than two tags found. Cannot perform operation.");
      return 0;
    }
    
    // Assuming we want to count between the first and second occurrence
    let charCount = 0;
    let counting = false;
    let txt = ""
    
    // Traverse the child nodes of the body
    const walkNodes = (node: ChildNode) => {
      if (node === tags[0]) { // Start counting after the first tag
        counting = true;
      } else if (node === tags[1]) { // Stop counting before the second tag
        counting = false;
      } else if (counting && node.nodeType === Node.TEXT_NODE) {
        txt += node.textContent;
        charCount += node.textContent?.length || 0; // Count characters in text nodes
      }
      
      node.childNodes.forEach(walkNodes); // Recursively walk through child nodes
    };
    
    walkNodes(doc);
    
    return txt.replace(/\r?\n/g, "").trim().length;
  }

  
  private _countCharsBetween(start: string, end: string) {
    const document = this.html;
    const startTag = document.querySelector(start);
    const endTag = document.querySelector(end);

    if (!startTag || !endTag) return 0;

    const startPos =
      document.innerHTML.indexOf(startTag.outerHTML) +
      startTag.outerHTML.length;
    const endPos = document.innerHTML.indexOf(endTag.outerHTML);
    const text = document.innerHTML.substring(startPos, endPos);
    const charCount = this._charCount(text);

    return charCount;
  }

  private _countWordsBetweenH2() {
    const document = this.html;

    const h1Tag = document.querySelector('h1');
    const h2Tags = document.querySelectorAll('h2');
    const countRes: CountRes[] = [];
    let index = 1;

    // Count words between the first h1 and h2 tags, if present
    if (h1Tag && h2Tags.length > 0) {
      let wordCount = 0;
      let sibling = h1Tag.nextElementSibling;
      let endPos = this._charCount(h1Tag?.textContent);

      while (sibling && sibling !== h2Tags[0]) {
        wordCount += this._wordCount(sibling?.textContent);
        endPos += this._charCount(sibling?.textContent);
        sibling = sibling.nextElementSibling;
      }

      countRes.push({
        startPos: document.innerHTML.indexOf(h1Tag.outerHTML),
        endPos,
        wordCount,
        index,
      });
      index++;
    }

    // Count words before the first h2 tag, if present and not directly after h1 tag
    else if (h2Tags.length > 0) {
      let wordCount = 0;
      let sibling = h2Tags[0].previousElementSibling;

      while (sibling) {
        const words = sibling.textContent?.split(/\s+/);
        wordCount += words?.length || 0;
        sibling = sibling.previousElementSibling;
      }

      countRes.push({
        startPos: 0,
        endPos: document.innerHTML.indexOf(h2Tags[0].outerHTML),
        wordCount,
        index,
      });
      index++;
    }

    // Count words between each pair of h2 tags and after the last h2 tag
    for (let i = 0; i < h2Tags.length; i++) {
      let start = h2Tags[i];
      let end = i !== h2Tags.length - 1 ? h2Tags[i + 1] : null;

      let wordCount = 0;
      let sibling = start.nextElementSibling;

      while (sibling && sibling !== end) {
        const words = sibling.textContent?.split(/\s+/);
        wordCount += words?.length || 0;
        sibling = sibling.nextElementSibling;
      }

      countRes.push({
        startPos: document.innerHTML.indexOf(start.outerHTML),
        endPos: end
          ? document.innerHTML.indexOf(end.outerHTML)
          : document.innerHTML.length,
        wordCount,
        index,
      });
      index++;
    }

    return countRes;
  }
}

export default QualityCheck;

interface CountRes {
  startPos: number;
  endPos: number;
  wordCount: number;
  index: number;
}
