import { AiInterfaceDetailsForm } from '../pages/components/AiInterfaceDetails';
import request, { API_BASE_URL } from '../utils/request';

/*
 * Generate result data
 * Interfaces and fetch functionality for the html code that was generated
 */

export interface IGenerateResult {
  html: string;
}

export const fetchResult = async (data: AiInterfaceDetailsForm) => {
  const res = await request<IGenerateResult>('/result', {
    method: 'post',
    data,
  });
  return res;
};

export const newFetchResultFinal = async (data: any) => {
  const res = await request<any>('/result/final', {
    method: 'post',
    data,
  });
  return res;
};


export const sendFinalOutput = async (data: any) => {
  const res = await request<any>('/statistics', {
    method: 'post',
    data,
  });
  return res;
};


export const newFetchResultStream = async (data: any, auth: any) => {
  // ToDo implement with umi-request

  return await fetch(API_BASE_URL + '/result/stream', {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${auth.token}`,
      'X-Organization': `${auth.organization}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
};

export const fetchFastResult = async (data: AiInterfaceDetailsForm) => {
  // ToDo implement with umi-request

  return await fetch(API_BASE_URL + '/fastResult', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
};
