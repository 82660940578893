import {
  CheckOutlined,
  EyeOutlined,
  QuestionCircleOutlined,
  UnorderedListOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { Button, List, Modal, Tooltip, Typography } from 'antd';
import React, { useState } from 'react';
import QualityCheck, { QualityCheckItem } from '../../../utils/qualityCheck';
import AiInterfaceResultQualityCheckLinkDetail from './AiInterfaceResultQualityCheckLinkDetail';

interface AiInterfaceResultQualityCheckProps {
  qcRes: QualityCheck | undefined;
  mark: (item: QualityCheckItem) => void;
  clearMark: (removeHighlightKeywordsFlag?: boolean) => void;
  highlighterType: string | null;
}

const AiInterfaceResultQualityCheck: React.FC<
  AiInterfaceResultQualityCheckProps
> = (props) => {
  const { mark, clearMark, qcRes, highlighterType } = props;
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [activeItem, setActiveItem] = useState<QualityCheckItem>();

  if (!qcRes) return null;

  return (
    <>
      <List
        header={
          <strong>
            <Typography.Text type={qcRes.failed === 0 ? 'success' : 'danger'}>
              {qcRes.passed} / {qcRes.total}
            </Typography.Text>{' '}
            quality checks passed
          </strong>
        }
        bordered
        dataSource={qcRes.items}
        renderItem={(item) => (
          <List.Item
            actions={[
              ...(item.canHighlightIssue && !item.passed
                ? [
                    <Tooltip title="Highlight issues">
                      <Button
                        type={
                          highlighterType === item.title ? 'default' : 'text'
                        }
                        onClick={() =>
                          highlighterType !== item.title
                            ? mark(item)
                            : clearMark()
                        }
                      >
                        <EyeOutlined />
                      </Button>
                    </Tooltip>,
                  ]
                : []),
              ...(!!item.details && !item.passed
                ? [
                    <Tooltip title="Show details">
                      <Button
                        type="text"
                        onClick={() => {
                          setModalVisible(true);
                          setActiveItem(item);
                        }}
                      >
                        <UnorderedListOutlined />
                      </Button>
                    </Tooltip>,
                  ]
                : []),
              <Tooltip title={item.goal}>
                <QuestionCircleOutlined />
              </Tooltip>,
            ]}
          >
            <List.Item.Meta
              avatar={
                item.passed ? (
                  <CheckOutlined style={{ color: 'green' }} />
                ) : (
                  <WarningOutlined style={{ color: 'orange' }} />
                )
              }
              title={item.title}
              description={
                item.passed ? item.passedMessage : item.failedMessage
              }
            />
          </List.Item>
        )}
      />
      <Modal
        width={1024}
        title="Quality check details"
        open={modalVisible}
        okButtonProps={{ style: { display: 'none' } }}
        cancelText="Close"
        onCancel={() => setModalVisible(false)}
      >
        {activeItem?.title === 'Internal links' && (
          <AiInterfaceResultQualityCheckLinkDetail item={activeItem!} />
        )}
      </Modal>
    </>
  );
};
export default AiInterfaceResultQualityCheck;
