import type { CollapseProps } from 'antd';
import { Button, Col, Collapse, Modal, Row, Typography } from 'antd';
import { diceCoefficient } from 'dice-coefficient';
import { decode } from 'html-entities';
import { Duration } from 'luxon';
import React from 'react';
import ReactDiffViewer from 'react-diff-viewer-refined';
import { useSelector } from 'react-redux';
import { ResultStorageData } from '../../../data/result';
import usePermissions from '../../../hooks/usePermissions';
import { RootState } from '../../../redux';



interface AiInterfaceResultDetailsProps {
  savedResult: ResultStorageData | undefined; 
}

const POWERBI_BASE_URL = "https://app.powerbi.com/groups/96c16d14-4d71-4637-ba67-363254a0b9f3/reports/c1fd4db2-7ed5-4e40-8d76-078c3eb7b5df/";
const POWERBI_OUTPUT_URL = POWERBI_BASE_URL + "ReportSection"
const POWERBI_PROMPT_URL = POWERBI_BASE_URL + "ReportSection3386f2fbe87c7fc79d36"

const AiInterfaceResultDetails: React.FC<AiInterfaceResultDetailsProps> = ({savedResult}) => {
  const {isAdmin} = usePermissions();
  const { params } = useSelector((state: RootState) => state.generation);
  const settings = useSelector((state: RootState) => state.branding.settings);
  const [modal, contextHolder] = Modal.useModal();

  const formatDuration = () => Duration.fromObject({milliseconds:savedResult?.editDuration || 0}).toFormat('hh:mm:ss');

  const viewDiff = () => {
    const oldT = decode(savedResult?.initText);
    const newT = decode(savedResult?.text);
    const similarity = diceCoefficient(oldT, newT);

    modal.info({
      title: 'Diff between initial and latest variant (Similarity: '+Math.round(similarity*100)+"%)",
      width: 1400,
      content: <ReactDiffViewer oldValue={oldT} newValue={newT} splitView={true} />
    });
  }


  function generateItems(): CollapseProps['items'] {
    // no type checking - bad temporary solution sorry!
    const anyParams: any = params;
    const primaryKeyword = params.primaryKeyword;
    const model = anyParams.model ? String(anyParams.model) : 'Not Specified';
    const language = anyParams.language
      ? String(anyParams.language)
      : 'Not Specified';
    const searchIntent = anyParams.tone;
    const links = settings?.brand_specific_input_fields?.filter(i => i.type === "brandList");


    const brandSpecific = anyParams.brandSpecific ?? {};
    const selectedKeyWords =
      (brandSpecific.keywords?.filter((x: any) => x.category === 'used') || [])
        .length > 0
        ? brandSpecific.keywords.filter((x: any) => x.category === 'used')
        : [];
    const removedKeyWords =
      (
        brandSpecific.keywords?.filter((x: any) => x.category === 'removed') ||
        []
      ).length > 0
        ? brandSpecific.keywords.filter((x: any) => x.category === 'removed')
        : [];
    const scrapedUrls =
      brandSpecific.scrapeUrls?.length > 0 ? brandSpecific.scrapeUrls : [];
    const faqs = brandSpecific.faqs?.length > 0 ? brandSpecific.faqs : [];

    return [
      {
        key: '1',
        label: 'Keywords',
        children: (
          <p>
            <p>
              <strong>Primary Keyword:</strong>
              <br /> {primaryKeyword}
            </p>
            <p>
              <strong>Selected Keywords (Used Category):</strong>
            </p>
            <ul>
              {selectedKeyWords.length > 0 ? (
                selectedKeyWords.map((x: any, index: number) => (
                  <li key={index}>{x.label}</li>
                ))
              ) : (
                <li>No Selected Keywords</li>
              )}
            </ul>
            <p>
              <strong>Excluded Keywords:</strong>
            </p>
            <ul>
              {removedKeyWords.length > 0 ? (
                removedKeyWords.map((x: any, index: number) => (
                  <li key={index}>{x.label}</li>
                ))
              ) : (
                <li>No Unselected Keywords</li>
              )}
            </ul>
            {!settings?.disableGoogleLinkModule && <><p>
              <strong>Crawled pages for secondary keywords</strong>
            </p>
            <ul>
              {scrapedUrls.length > 0 ? (
                scrapedUrls.map((x: any, index: number) => (
                  <li key={index}>{x}</li>
                ))
              ) : (
                <li>No URLs Scraped</li>
              )}
            </ul></>}
          </p>
        ),
      },
      ...(links?.length ? [{
        key: '2',
        label: 'Internal Links',
        children: (
          <div>
            {links.map(link => {
              const name = Array.isArray(link.name) ? link.name : [link.name];
              // @ts-ignore
              const value = name!.reduce((acc: any, key: string) => acc && acc[key], anyParams as any)
              return <>
              <p>
                <strong>{link.label}:</strong>
              </p>
              <ul>
                {value?.length > 0 ? (
                  value.map((brand: any, index: number) => (
                    <li key={index}>
                      {brand.name} (
                      <a
                        href={`https://${brand.link}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {brand.link}
                      </a>
                      )
                    </li>
                  ))
                ) : (
                  <li>No URLs</li>
                )}
              </ul>
              </>;
            })}
          </div>
        ),
      }] : []),
      ...((faqs.length) ? [{
        key: '3',
        label: 'FAQs',
        children: (
          <div>
            <ul>
              {faqs.length > 0 ? (
                faqs.map((faq: any, index: number) => (
                  <li key={index}>{faq.label}</li>
                ))
              ) : (
                <li>No FAQs Available</li>
              )}
            </ul>
          </div>
        ),
      }] : []),
      {
        key: '4',
        label: 'Other Settings',
        children: (
          <div>
            <p>
              <strong>Model:</strong>
              <br /> {model || "-"} {!!anyParams.temperature && <>(Temperature: {anyParams.temperature})</>}
            </p>
            <p>
              <strong>Search Intent:</strong>
              <br /> {searchIntent || "-"}
            </p>
            <p>
              <strong>Language:</strong>
              <br /> {language || "-"}
            </p>
            {settings?.brand_specific_input_fields?.filter(field => !!anyParams[field.name]).map(field =>   <p key={field.name}>
              <strong>{field.label}:</strong>
              <br /> {anyParams[field.name]}
            </p>)}
          </div>
        ),
      },
      ...(isAdmin && !!savedResult ? [{
        key: '5',
        label: 'Saved item',
        children: (
          <div>
            <p>
              <strong>Init text available:</strong>
              <br /> {!!savedResult.initText ? "yes" : "no"} {!!savedResult.initText && <Button size="small" onClick={() => viewDiff()}>View diff</Button>}
            </p>
            <p>
              <strong>Item ID:</strong>
              <br /> <Typography.Text copyable>{savedResult.id}</Typography.Text>
            </p>
            <p>
              <strong>UUID:</strong>
              <br /> <Typography.Text copyable>{savedResult.generationUuid}</Typography.Text>
            </p>
            <p>
              <strong>Focus duration:</strong>
              <br /> <Typography.Text copyable>{formatDuration()}h</Typography.Text>
            </p>
            <p>
              <strong>Output analytics:</strong>
              <br /> <Typography.Text copyable>{POWERBI_OUTPUT_URL + encodeURIComponent("filter=final_output/customDimensions.uuid eq '"+savedResult.generationUuid+"'")}</Typography.Text>
            </p>
            <p>
              <strong>Prompt analytics:</strong>
              <br /> <Typography.Text copyable>{POWERBI_PROMPT_URL + encodeURIComponent("filter=final_output/customDimensions.uuid eq '"+savedResult.generationUuid+"'")}</Typography.Text>
            </p>
          </div>
        ),
      }]: []),
    ];
  }

  return (
    <Row wrap={false} style={{ marginBottom: 10 }}>
      <Col flex="auto">
        <Collapse items={generateItems()} />
      </Col>
      {contextHolder}
    </Row>
  );
};
export default AiInterfaceResultDetails;
