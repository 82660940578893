import ProTable, { ProColumns } from '@ant-design/pro-table';
import { Breadcrumb, Button, Card, Col, Input, InputRef, Modal, Popover, Row, message } from 'antd';
import { DateTime } from 'luxon';
import React, { useEffect, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Feedback from '../../components/layout/Feedback';
import {
  ResultStorageData,
  resultStorageList
} from '../../data/result';
import useSavedItem from '../../hooks/useSavedItem';
import { RootState } from '../../redux';
import { replaceGermanUmlauts, stripHtml } from '../../utils/htmlFix';
import '../styles.css';

const SavedList: React.FC = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [modal, modalContextHolder] = Modal.useModal();
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<ResultStorageData[]>([]);
  const [name, setName] = useState<string>();
  const [filteredData, setFilteredData] = useState<ResultStorageData[]>([]);
  const branding = useSelector((state: RootState) => state.branding.brand);
  const nameRef = useRef<InputRef | null>(null)
  const navigate = useNavigate();
  const { deleteSavedItem, shareSavedItem, savedItemLink } = useSavedItem();

  useHotkeys('ctrl+f', (e) => {
    nameRef.current?.focus()
    e.preventDefault();
  }, [])


  const loadData = async () => {
    if (!branding) return;
    setLoading(true);
    const res: any = await resultStorageList();
    setData(res.list);
    setLoading(false);
  };
  useEffect(() => {
    loadData();
  }, [branding]);
  useEffect(() => {
    filterData();
  }, [data, name]);

  const columns: ProColumns<ResultStorageData>[] = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (_, it) => (
        <a href={savedItemLink(it.id)} onClick={(e) => e.preventDefault()}>
          {_}
        </a>
      ),
      sorter: (a, b) => a.name?.localeCompare(b.name || ''),
    },
    {
      title: 'Result preview',
      dataIndex: 'text',
      hideInSearch: true,
      render: (_, it) => (
        <Popover>
          {stripHtml(replaceGermanUmlauts(it.text?.slice(0, 300)))}
          {it.text?.length > 300 && '...'}
        </Popover>
      ),
    },
    {
      title: 'Created',
      dataIndex: 'initTimestamp',
      valueType: 'dateRange',
      search: {
        transform: (value) => {
          return {
            startTime: value[0],
            endTime: value[1],
          };
        },
      },
      render: (_, it) =>
        _
          ? DateTime.fromISO(it.initTimestamp!).toLocaleString(
            DateTime.DATETIME_FULL,
          )
          : ' - ',
      sorter: (a, b) =>
        DateTime.fromISO(a.initTimestamp || '').toMillis() -
        DateTime.fromISO(b.initTimestamp || '').toMillis(),
    },
    {
      title: 'Edited',
      dataIndex: 'timestamp',
      valueType: 'date',
      hideInSearch: true,
      render: (_, it) =>
        _
          ? DateTime.fromISO(it.timestamp!).toLocaleString(
            DateTime.DATETIME_FULL,
          )
          : ' - ',
      sorter: (a, b) =>
        DateTime.fromISO(a.timestamp || '').toMillis() -
        DateTime.fromISO(b.timestamp || '').toMillis(),
    },
    {
      title: 'Options',
      key: 'option',
      hideInSearch: true,
      valueType: 'option',
      render: (_, it) => [
        <Button
          key="delete"
          danger
          type="link"
          onClick={(e) => {
            deleteSavedItem(it, modal, messageApi);
            e.stopPropagation();
          }}
        >
          Delete
        </Button>,
        <Button
          key="share"
          type="link"
          onClick={(e) => {
            shareSavedItem(it, messageApi);
            e.stopPropagation();
          }}
        >
          Share
        </Button>,
      ],
    },
  ];

  const filterData = () => {
    const regex = new RegExp(name || "", 'i');
    const newDataSet =
      data?.filter(i => !name || regex.test(i.name))
    setFilteredData(newDataSet)
  }

  return (
    <>
      {contextHolder}

      <Breadcrumb
        items={[
          {
            title: 'Home',
            onClick: () => navigate('/'),
          },
          {
            title: "My organization's results",
          },
        ]}
      />

      <Card style={{ marginTop: 10 }}>
        <Row>
          <Col flex="auto">
            <Input placeholder="Type a name to filter..." onChange={val => setName(val.target.value)} value={name} allowClear ref={nameRef} />
          </Col>
        </Row>
      </Card>

      <ProTable<ResultStorageData>
        rowKey="key"
        pagination={{
          showQuickJumper: true,
        }}
        columns={columns}
        search={false}
        onRow={(record) => {
          return {
            onClick: () => navigate('/saved/' + record.id),
          };
        }}
        dataSource={filteredData}
        loading={loading}
        dateFormatter="string"
        headerTitle="My organization's results"
      />
      <Feedback step="saved_list" additionalInput={{filterName: name, visibleLength: filterData?.length, totalLength: data?.length}} />
      {modalContextHolder}
    </>
  );
};
export default SavedList;
