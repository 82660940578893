import { CopyOutlined, FileOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Button, Dropdown, Tooltip } from 'antd';
import { MessageInstance } from 'antd/es/message/interface';
import React, { MutableRefObject } from 'react';
import { useSelector } from 'react-redux';
import { sendFinalOutput } from '../../../data/generateResult';
import { RootState } from '../../../redux';
import { centralTransformHtml } from '../../../utils/copyHtml';

interface AiInterfaceResultCopyProps {
  messageApi: MessageInstance;
  editorRef: React.MutableRefObject<any>;
  elementRef: React.MutableRefObject<HTMLDivElement | null>;
  uuidRef: MutableRefObject<string | null>;
  loading: boolean;
}

function extractH2Segments(html: string): string[] {
  const segments: string[] = [];

  // Extract intro text before the first <h2>
  const introRegex = /^(.*?)(?=<h2>)/s;
  const introMatch = introRegex.exec(html);
  if (introMatch && introMatch[0].trim()) {
    segments.push(introMatch[0].trim()); // Add intro text
  }

  // Regex to capture each <h2> and the content following it until the next <h2> or the end of the string
  const segmentRegex = /(<h2>.*?<\/h2>)(.*?)(?=<h2>|$)/gs;

  let match;
  while ((match = segmentRegex.exec(html)) !== null) {
    // This concatenates the <h2> tag with its following content
    const fullSegment = match[1].trim() + match[2].trim();
    segments.push(fullSegment);
  }

  return segments;
}

const AiInterfaceResultCopy: React.FC<AiInterfaceResultCopyProps> = (props) => {
  const { messageApi, editorRef, elementRef, uuidRef, loading } = props;
  const settings = useSelector((state: RootState) => state.branding.settings);

  const items: MenuProps['items'] = [
    {
      key: '0',
      label: 'Introduction',
    },
    {
      key: '1',
      label: 'Text',
    },
    {
      key: '2',
      label: 'FAQ',
    },
    {
      key: 'div1',
      type: "divider"
    },
    {
      key: 'all',
      label: 'Entire document',
      icon: <FileOutlined />
    },
  ];

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    const ctrlPressed = e.domEvent.ctrlKey && e.domEvent.altKey;
    let res: any = undefined;
    let content = '';

    if (ctrlPressed) {
      res = centralTransformHtml(elementRef.current!);
      content = res.transformedContent;
      if (e.key !== 'all') {
        content = res.sections[Number(e.key)]
      }
    } else {
      res = editorRef.current?.getContent();
      if (e.key !== 'all') {
        content = extractH2Segments(res)?.[
          Number(e.key)
        ];
      } else {
        content = res;
      }
    }


    if (!content) {
      messageApi.error(
        'Sorry, we couldn\'t process your content because it\'s been changed significantly from the original format (for example, new H2 headlines were added, or unexpected HTML elements were included). Please stick closer to the default format and try again, or copy the entire document.',
        10000
      );
    } else {
      copyToClipboard(content);
    }

    // for statistics purpose, send final output to backend
    sendFinalOutput({
      copy: true,
      output: editorRef.current?.getContent(),
      uuid: uuidRef.current,
      additionalInput: {
        content
      }
    });
  };

  // copy to clipboard functionality
  const copyToClipboard = (content: string | undefined = undefined) => {
    if (!content) content = editorRef.current?.getContent();

    // prepare as html and plaintext
    const blobHtml = new Blob([content!], {
      type: 'text/html',
    });
    const blobPlain = new Blob([content!], {
      type: 'text/plain',
    });
    const data = [
      new ClipboardItem({
        'text/html': blobHtml,
        'text/plain': blobPlain,
      }),
    ];

    // for statistics purpose, send final output to backend
    sendFinalOutput({
      copy: true,
      output: editorRef.current?.getContent(),
      uuid: uuidRef.current,
    });

    // write to clipboard
    navigator.clipboard.write(data).then(
      () => {
        messageApi.open({
          type: 'success',
          content: 'HTML code was successfully copied to clipboard',
        });
      },
      () => {
        messageApi.open({
          type: 'error',
          content:
            'Could not copy HTML code to clipboard - please check your browser settings',
        });
      },
    );
  };

  const CopyButton = (hasOnClick: boolean) => {
    return (
      <Tooltip
        title={loading ? 'Text is still generated, please wait' : undefined}
      >
        <Button
          type="primary"
          htmlType="submit"
          icon={<CopyOutlined />}
          disabled={loading}
          onClick={hasOnClick ? () => copyToClipboard() : undefined}
        >
          Copy HTML
        </Button>
      </Tooltip>
    );
  };

  switch (settings?.copyOutputHandler) {
    // can add switch for different handlers later
    case 'central':
      return (
        <Dropdown
          menu={{
            items,
            onClick: handleMenuClick
          }}
        >
          {CopyButton(false)}
        </Dropdown>
      );
    default:
      return CopyButton(true);
  }
};

export default AiInterfaceResultCopy;
