import { configureStore } from '@reduxjs/toolkit';
import BrandingReducer from './branding';
import AuthReducer from './auth';
import GenerationProcessReducer from './generationProcess';
export const store = configureStore({
  reducer: {
    branding: BrandingReducer,
    generation: GenerationProcessReducer,
    auth: AuthReducer,
  },
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
